import { Backdrop, Button, CircularProgress, Container, Divider, FormControl, Grid, InputLabel, MenuItem, Select, SvgIcon } from "@mui/material";
import { PageTable } from "../../components/table/pageTable";
import FilterAltOffRoundedIcon from '@mui/icons-material/FilterAltOffRounded';
import { useCallback, useEffect, useState } from "react";
import { getOverviewPayment } from "../../service/paymentService";
import _ from "lodash";
import { getClassDetails } from "../../service/dashboardService";
import DialogBox from "../../components/dialogbox/DialogBox";
import UploadIcon from '@mui/icons-material/Upload';
import * as XLSX from 'xlsx';
import { Helmet } from "react-helmet-async";


export default function PaymentDetails() {

    const exportToExcel = (jsonData, fileName) => {
        const ws = XLSX.utils.json_to_sheet(jsonData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        XLSX.writeFile(wb, `${fileName}.xlsx`);
    }

    const applySearch = useCallback(async (classes, section, academic_year, type, term, feeType, pages, rows, status) => {
        try {
            setError('');
            setLoader(true);
            setCount(0);
            let formattedResponse;
            const payload = {
                params: {
                    query: {
                        standard: classes ? classes.toString() : null,
                        section,
                        academicYear: academic_year,
                        term,
                        feeType,
                        status
                    },
                    pageNumber: pages + 1,
                    pageSize: rows,
                    type
                }
            }
            const response = await getOverviewPayment(payload);
            if (response && response.data) {
                formattedResponse = formatOverviewData(response.data);
                if (formattedResponse) {
                    if (type === 'export') {
                        exportToExcel(formattedResponse, 'paymentdetails_data');
                    } else {
                        setFormattedData(formattedResponse);
                    }
                } else {
                    setFormattedData([]);
                }
            } else {
                setError('No Data Found');
            }
            setLoader(false);
        } catch (error) {
            setError(error);
        }
        // eslint-disable-next-line
    }, []);
    const [selectedClass, setSelectedClass] = useState(null);
    const [selectedSection, setSelectedSection] = useState(null);
    const [loader, setLoader] = useState(false);
    const [selectedAcYear, setSelectedAcYear] = useState(null);
    const [formattedData, setFormattedData] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [classSection, setClassSection] = useState([]);
    const [error, setError] = useState('');
    const [count, setCount] = useState(0);
    const [headerValues, setHeaderValues] = useState(0);
    const tuitionTerms = ['TERM I', 'TERM II', 'TERM III'];
    const vanTerms = ['VAN TERM I', 'VAN TERM II', 'VAN TERM III'];
    const paymentStatuses = ['Paid', 'Not Paid', 'Pending', 'Default'];
    const [termDetails, setTermDetails] = useState(tuitionTerms);
    const [selectedTerm, setSelectedTerm] = useState(termDetails?.[0]);
    const [selectedFeeType, setSelectedFeeType] = useState('tuitionFee');
    const [paymentStatus, setPaymentStatus] = useState(null);

    useEffect(() => {
        const getClassSectionDetails = async () => {
            try {
                setError('');
                const response = await getClassDetails();
                if (response) {
                    setClassSection(response.data);
                }
            } catch (error) {
                setError(error);
            }
        }
        getClassSectionDetails();
    }, []);

    const academicYear = ['2023-2024', '2024-2025'];

    const clearAllFilters = () => {
        setSelectedAcYear(null);
        setSelectedClass(null);
        setSelectedSection(null);
        setTermDetails(tuitionTerms);
        setSelectedTerm(tuitionTerms[0]);
        setPaymentStatus(null);
    }

    useEffect(() => {
        applySearch(selectedClass, selectedSection, selectedAcYear, null, selectedTerm, selectedFeeType, page, rowsPerPage, paymentStatus);
    }, [applySearch, selectedClass, selectedSection, selectedAcYear, selectedTerm, selectedFeeType, page, rowsPerPage, paymentStatus])

    const handlePageChange = (event, value) => {
        setPage(value);
    }


    const onFeeTypeChange = (e) => {
        setSelectedFeeType(e.target.value);
        setTermDetails(e.target.value === 'tuitionFee' ? tuitionTerms : vanTerms);
        setSelectedTerm(e.target.value === 'tuitionFee' ? tuitionTerms[0] : vanTerms[0]);
    }

    const handleRowsPerPageChange = async (event) => {
        setRowsPerPage(event.target.value);
    }

    const exportTable = async () => {
        await applySearch(selectedClass, selectedSection, selectedAcYear, 'export', selectedTerm, selectedFeeType, page, rowsPerPage, paymentStatus);
    };

    const formatOverviewData = (response) => {
        if (response.students && response.students.length) {
            setCount(response.totalCount);
            let results = [];
            let headers = [];
            response.students.forEach((eachStudent) => {
                const obj = {};
                obj.name = eachStudent.firstName + ' ' + eachStudent.lastName;
                obj.class = eachStudent.class;
                obj.section = eachStudent.section;
                obj[eachStudent?.feeDetails?.feeName + ' Amount'] = eachStudent?.feeDetails?.amount['$numberDecimal'];
                obj[eachStudent?.feeDetails?.feeName + ' Status'] = eachStudent?.feeDetails?.status;
                headers.push(eachStudent?.feeDetails?.feeName + ' Amount');
                headers.push(eachStudent?.feeDetails?.feeName + ' Status');
                results.push(obj);
            })
            headers = _.uniq(headers);
            const headerObj = {};
            headerObj.name = 'Name';
            headerObj.class = 'Class';
            headerObj.section = 'Section';
            headers.forEach((eachHeader) => {
                headerObj[eachHeader] = eachHeader;
            });
            setHeaderValues(headerObj);
            return results;
        }
    }
    return (
        <>
            <Helmet>
                <title>
                    Payment Details | Page
                </title>
            </Helmet>
            <Container>
                {error && error !== '' && <DialogBox content={error} />}
                <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loader}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Grid container spacing={2} sx={{ justifyContent: 'flex-end' }}>
                    <Grid item>
                        <Button
                            sx={{
                                backgroundColor: (theme) => theme.palette.grey[400]
                            }}
                            color="inherit"
                            startIcon={(
                                <SvgIcon fontSize="small">
                                    <UploadIcon />
                                </SvgIcon>
                            )}
                            onClick={exportTable}
                        >
                            Export
                        </Button>
                    </Grid>
                </Grid>
                <Divider sx={{
                    margin: (theme) => theme.spacing(2)
                }} />
                <Grid container spacing={2}>
                    <Grid item><FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                        <InputLabel id="select-feetype-label">Fee Type</InputLabel>
                        <Select
                            labelId="select-feetype-label"
                            label="FeeType"
                            value={selectedFeeType}
                            onChange={(event) => onFeeTypeChange(event)}
                        >
                            <MenuItem key="tuitionFee" value='tuitionFee'>Tuition Fee</MenuItem>
                            <MenuItem key="transportFee" value='transportFee'>Van Fee</MenuItem>
                        </Select>
                    </FormControl></Grid>
                    <Grid item><FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                        <InputLabel id="select-term-label">Term</InputLabel>
                        <Select
                            labelId="select-term-label"
                            label="Term"
                            value={selectedTerm}
                            disabled={selectedFeeType === ''}
                            onChange={(event) => setSelectedTerm(event.target.value)}
                        >
                            {termDetails.length ? termDetails.map(eachTerm => <MenuItem key={eachTerm} value={eachTerm}>{eachTerm}</MenuItem>) : null}
                        </Select>
                    </FormControl></Grid>
                    <Grid item><FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                        <InputLabel id="select-class-label">Class</InputLabel>
                        <Select
                            labelId="select-class-label"
                            label="Class"
                            value={selectedClass}
                            onChange={(event) => setSelectedClass(event.target.value)}
                        >
                            {classSection.length ? classSection.map(eachClass => <MenuItem key={eachClass.standard} value={eachClass.standard}>{eachClass.standard}</MenuItem>) : null}
                        </Select>
                    </FormControl></Grid>
                    <Grid item><FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                        <InputLabel id="select-section-label">Section</InputLabel>
                        <Select
                            labelId="select-section-label"
                            label="Section"
                            value={selectedSection}
                            disabled={!selectedClass}
                            onChange={(event) => setSelectedSection(event.target.value)}
                        >
                            {classSection.filter(eachClass => eachClass.standard === selectedClass)?.[0]?.section.sort().map(eachSection => <MenuItem key={eachSection} value={eachSection}>{eachSection}</MenuItem>)}
                        </Select>
                    </FormControl></Grid>
                    <Grid item><FormControl sx={{ m: 1, minWidth: 150 }} size="small">
                        <InputLabel>Academic Year</InputLabel>
                        <Select
                            labelId="select-academic-year-label"
                            label="AcademicYear"
                            value={selectedAcYear}
                            onChange={(event) => setSelectedAcYear(event.target.value)}
                        >
                            {academicYear.length ? academicYear.map(eachAcademicYear => <MenuItem key={eachAcademicYear} value={eachAcademicYear}>{eachAcademicYear}</MenuItem>) : null}
                        </Select>
                    </FormControl></Grid>
                    <Grid item><FormControl sx={{ m: 1, minWidth: 150 }} size="small">
                        <InputLabel>Status</InputLabel>
                        <Select
                            labelId="select-academic-year-label"
                            label="Status"
                            value={paymentStatus}
                            onChange={(event) => setPaymentStatus(event.target.value)}
                        >
                            {paymentStatuses.length ? paymentStatuses.map(eachStatus => <MenuItem key={eachStatus} value={eachStatus}>{eachStatus}</MenuItem>) : null}
                        </Select>
                    </FormControl></Grid>
                    <Grid item>
                        <Button sx={{
                            margin: '10px'
                        }}
                            onClick={clearAllFilters}
                            startIcon={<FilterAltOffRoundedIcon />}
                            variant="contained"
                        >
                            Clear Filter
                        </Button>
                    </Grid>
                </Grid>
                <Divider sx={{
                    margin: (theme) => theme.spacing(2)
                }} />
                <PageTable
                    items={formattedData}
                    headerValues={headerValues}
                    count={count ? count : formattedData.length}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleRowsPerPageChange}
                    page={page}
                    displayNA={count ? true : false}
                    rowsPerPage={rowsPerPage}
                    multiSelect={false}
                />
            </Container >
        </>
    );
}