export const classes = ['LKG', 'UKG', 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

export const section = ['A', 'B', 'C'];

export const studentTableHeader = {
    'name': 'Name',
    'class': 'Class',
    'address': 'Address',
    'gender': 'Gender',
    'admissionNumber': 'Admission Number',
    'delflag': ''
}

export const staffTableHeader = {
    'name': 'Name',
    'qualification': 'Qualification',
    'address': 'Address',
    'gender': 'Gender',
    'employeeId': 'Employee ID'
}

export const allFileTypes = {
    pdf: ['application/pdf'],
    document: ['.txt', '.rtf', '.doc', '.docx', '.odt'],
    spreadsheet: ['.xls', '.xlsx', 'csv'],
    image: ['.jpg', '.png', '.gif', '.tiff', '.ico', '.svg', '.webp'],
    audio: ['.mp3', '.wav', '.ogg'],
    video: ['.avi', '.mov', '.mp4', '.ogg', '.wmv', '.webm'],
    presentation: ['.ppt', '.pptx'],
};

export const import_student_details = {
    'firstName': 'First Name',
    'lastName': 'Last Name',
    'dateOfBirth': 'Date of Birth',
    'gender': 'Gender',
    'dateOfAdmission': 'Date of Admission',
    'fatherName': 'Father Name',
    'fatherPhone': 'Father Phone',
    'motherName': 'Mother Name',
    'parentsOccupation': 'Parents Occupation',
    'parentsAnnualIncome': 'Parents Annual Income',
    'motherTongue': 'Mother Tongue',
    'aadharNumber': 'Aadhar Number',
    'address': 'Address',
    'city': 'City',
    'state': 'State',
    'pinCode': 'Pin Code',
    'class': 'Class',
    'section': 'Section',
    'admissionNumber': 'Admission Number',
    'bloodGroup': 'Blood Group',
    'religion': 'Religion',
    'caste': 'Caste',
    'community': 'Community'
};
export const import_staff_details = {
    'firstName': 'First Name',
    'lastName': 'Last Name',
    'employeeId': 'Employee ID',
    'qualification': 'Qualification',
    'dateOfBirth': 'Date of Birth',
    'gender': 'Gender',
    'email': 'Email',
    'phone': 'Phone Number',
    'address': 'Address',
    'city': 'City',
    'state': 'State',
    'dateOfJoining': 'Joining Date',
    'department': 'Department',
    'designation': 'Designation',
    'emergencyContact': 'Emergency Contact Name',
    'emergencyContactNumber': 'Emergency Contact Number'
}


export const error_details = {
    's_no': 'S NO.',
    'error': 'Error',
    'user': 'Users'
}


export const recent_payments = {
    ref_no: 'Reference Number',
    name: 'Name',
    admissionNumber: 'Admission Number',
    class: 'Class',
    term: 'Term',
    academicYear: 'Academic Year',
    date: 'Date',
    chips: 'Status'
}

export const displayStatus = {
    'Paid': ['Success', 'Shipped'],
    'Pending': ['Awaited', 'Pending'],
    'Not Paid': ['Aborted', 'Auto-Cancelled', 'Auto-Reversed', 'Cancelled', 'Chargeback', 'Fraud', 'Initiated', 'Invalid', 'Refunded', 'Timeout', 'Unsuccessful']
}