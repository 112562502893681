import { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { PropTypes } from 'prop-types';
import { Alert, Box, TextField, Dialog, DialogTitle, Divider, DialogContent, Typography, Stack, Button, DialogActions } from "@mui/material";
import { verifyotp, resendotp } from '../../service/loginService';
import { setCookie } from '../../base/token';
import { useNavigate } from 'react-router-dom';
import { setUserDetails } from '../../base/userDetails';

OtpDialog.propTypes = {
    openDialog: PropTypes.bool.isRequired,
    onCloseDialog: PropTypes.func.isRequired,
    email: PropTypes.string,
    secretKey: PropTypes.string.isRequired,
    userName: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired
};

export default function OtpDialog({ openDialog, onCloseDialog, email, secretKey, userName, role }) {
    const inputRefs = useRef([]);
    const [remainingTime, setRemainingTime] = useState(60);
    const [inputValues, setInputValues] = useState(['', '', '', '', '', '']);
    const [focusedIndex, setFocusedIndex] = useState(0);
    const [submitted, setSubmitted] = useState(false);
    const [inputErrors, setInputErrors] = useState(new Array(6).fill(true));
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const handleInputChange = (index, event) => {
        const value = event.target.value.replace(/[^0-9]/g, '').slice(-1);
        const updatedValues = [...inputValues];
        updatedValues[index] = value;
        setInputValues(updatedValues);

        if (value && index < inputRefs.current.length - 1) {
            setFocusedIndex(index + 1);
        } else if (index > 0 && index < 5) {
            setFocusedIndex(index - 1);
        }
    };

    const handleInputKeyDown = (index, event) => {
        if (event.key === 'Backspace' || event.key === 'Delete') {
            event.preventDefault();
            event.stopPropagation();

            const updatedValues = [...inputValues];
            updatedValues[index] = '';
            setInputValues(updatedValues);

            if (index > 0) {
                setFocusedIndex(index - 1);
            }
        }
        if (event.key === "Enter") {
            submitOTP(event);
        }
    };

    const handleInputFocus = (index) => {
        setFocusedIndex(index);
    };

    useEffect(() => {
        if (remainingTime > 0) {
            const timer = setInterval(() => {
                setRemainingTime((prevTime) => prevTime - 1);
            }, 1000);
            return () => clearInterval(timer);
        }
    }, [remainingTime]);

    useEffect(() => {
        setInputErrors(inputValues.map(value => value === ''));
    }, [inputValues]);

    const formatTime = (timeInSeconds) => {
        const minutes = Math.floor(timeInSeconds / 60);
        const seconds = timeInSeconds % 60;
        if (remainingTime === 0)
            return '';
        return ` - ${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };

    const StyledTextField = styled(TextField)`
    input {
      text-align: center;
      padding: 16px 10px;
      border-color: ${({ theme, error }) => (error ? theme.palette.error.main : 'initial')};
      border-width: 2px;
    }`;

    const navigate = useNavigate();

    const submitOTP = async (e) => {
        e.preventDefault();
        setSubmitted(true);

        let submittedOTP = inputValues.map((eachInt) => eachInt).join('');
        if (submittedOTP.length === 6) {
            try {
                let response = await verifyotp(submittedOTP, secretKey);
                if (response?.data?.success) {
                    setCookie('token', response.data.message);
                    setUserDetails({ userName: userName, role: role });
                    onCloseDialog();
                    navigate('/dashboard', { replace: true });
                }
            }
            catch (error) {
                setError(true);
                setErrorMessage(error);
            }
        }
    }

    const resendOTP = async (event) => {
        event.preventDefault();
        try {
            let response = await resendotp(secretKey);
            setErrorMessage(response);
            setRemainingTime(60);
            startTimer();
        }
        catch (error) {
            if (error === 'jwt expired') {
                onCloseDialog();
            }
            else {
                setError(true);
                setErrorMessage(error);
            }
        }
    }

    const startTimer = () => {
        if (remainingTime > 0) {
            const timer = setInterval(() => {
                setRemainingTime((prevTime) => prevTime - 1);
            }, 1000);
            return () => clearInterval(timer);
        }
    }

    return (
        <>
            <Dialog open={openDialog ? openDialog : false} fullWidth>
                <DialogTitle sx={{ alignSelf: 'center' }}>OTP verification</DialogTitle>
                <Divider variant="middle" />
                <DialogContent>
                    <Typography>OTP has been successfully sent to the registered mail id - </Typography><Typography variant='h6'>{email}</Typography>
                    <Box sx={{ margin: '2%' }}>
                        <Stack direction='row' spacing={2}>
                            {[0, 1, 2, 3, 4, 5].map((index) => (
                                <Box key={index}>
                                    <StyledTextField type="number"
                                        variant="outlined"
                                        value={inputValues[index]}
                                        inputRef={(ref) => (inputRefs.current[index] = ref)}
                                        onChange={(event) => handleInputChange(index, event)}
                                        onKeyDown={(event) => handleInputKeyDown(index, event)}
                                        onFocus={() => handleInputFocus(index)}
                                        autoFocus={focusedIndex === index}
                                        error={submitted && inputErrors[index]}
                                        maxLength={1}
                                    />
                                </Box>
                            ))}
                        </Stack>
                    </Box>
                </DialogContent>
                {error && <Alert
                    severity="error"
                    sx={{ backgroundColor: "#FFB4A4", fontWeight: "bold", margin: "0 5% 2% 5%" }}
                    onClose={() => {
                        setError(false);
                    }}
                >
                    {errorMessage}
                </Alert>}
                <DialogActions>
                    <Button variant="contained" color="primary" disabled={remainingTime !== 0} onClick={(e) => resendOTP(e)}>Resend{formatTime(remainingTime)}</Button>
                    <Button variant="contained" color="primary" onClick={(event) => submitOTP(event)}>
                        Submit
                    </Button>
                    <Button variant="contained" color="primary" startIcon={<CloseRoundedIcon />} onClick={() => onCloseDialog()}>Close</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}