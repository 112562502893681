import { Helmet } from "react-helmet-async";
import DashboardWidgets from "../../components/dashboardwidgets/DashboradWidgets";
import { Container, Grid } from '@mui/material';
import { getUserDetails } from '../../base/userDetails'
import navConfig from '../../layouts/dashboard/nav/config'

export default function HomePage() {
    const userDetails = getUserDetails();
    const accessibleCards = navConfig.filter((eachConfig) => { return userDetails ? (eachConfig.access.indexOf(userDetails.role) !== -1 && eachConfig.title !== 'home') : [] });
    return (
        <>
            <Helmet>
                <title>
                    Home | Page
                </title>
            </Helmet>
            <Container>
                < Grid container spacing={2}>
                    {accessibleCards.map(eachCard => <Grid item key={eachCard.title} xs={12} sm={6} md={3}> <DashboardWidgets cardDetails={eachCard} /></Grid>)}
                </Grid>
            </Container>
        </>
    )
}