import { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Grid, FormControl, InputLabel, Select, Divider, MenuItem, Button, Container } from "@mui/material";
import FilterAltOffRoundedIcon from '@mui/icons-material/FilterAltOffRounded';
import Overview from "./components/overview";
import BarChart from "./components/barchart/BarChart";
import { getClassDetails } from "../../service/dashboardService";

export default function DashBoardPage() {

    const tuitionTerms = ['TERM I', 'TERM II', 'TERM III'];
    const vanTerms = ['VAN TERM I', 'VAN TERM II', 'VAN TERM III'];


    const [classSection, setClassSection] = useState([]);
    const [selectedClass, setSelectedClass] = useState('');
    const [selectedSection, setSelectedSection] = useState('');
    const [selectedFeeType, setSelectedFeeType] = useState('tuitionFee');
    const [termDetails, setTermDetails] = useState(tuitionTerms);
    const [selectedTerm, setSelectedTerm] = useState(termDetails?.[0]);



    useEffect(() => {
        const getClassSectionDetails = async () => {
            const response = await getClassDetails();
            if (response) {
                setClassSection(response.data);
            }
        }
        getClassSectionDetails();
    }, []);


    const onFeeTypeChange = (e) => {
        setSelectedFeeType(e.target.value);
        setTermDetails(e.target.value === 'tuitionFee' ? tuitionTerms : vanTerms);
        setSelectedTerm(e.target.value === 'tuitionFee' ? tuitionTerms[0] : vanTerms[0]);
    }

    const resetFilter = () => {
        setSelectedFeeType('tuitionFee');
        setTermDetails(tuitionTerms)
        setSelectedTerm(tuitionTerms[0]);
        setSelectedClass('');
        setSelectedSection('');
    }

    return (
        <>
            <Helmet>
                <title>
                    Dashboard | Page
                </title>
            </Helmet>
            <Container>
                <Grid container spacing={2}>
                    <Grid item><FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                        <InputLabel id="select-feetype-label">Fee Type</InputLabel>
                        <Select
                            labelId="select-feetype-label"
                            label="FeeType"
                            value={selectedFeeType}
                            onChange={(event) => onFeeTypeChange(event)}
                        >
                            <MenuItem key="tuitionFee" value='tuitionFee'>Tuition Fee</MenuItem>
                            <MenuItem key="transportFee" value='transportFee'>Van Fee</MenuItem>
                        </Select>
                    </FormControl></Grid>
                    <Grid item><FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                        <InputLabel id="select-term-label">Term</InputLabel>
                        <Select
                            labelId="select-term-label"
                            label="Term"
                            value={selectedTerm}
                            disabled={selectedFeeType === ''}
                            onChange={(event) => setSelectedTerm(event.target.value)}
                        >
                            {termDetails.length ? termDetails.map(eachTerm => <MenuItem key={eachTerm} value={eachTerm}>{eachTerm}</MenuItem>) : null}
                        </Select>
                    </FormControl></Grid>
                    <Grid item><FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                        <InputLabel id="select-class-label">Class</InputLabel>
                        <Select
                            labelId="select-class-label"
                            label="Class"
                            value={selectedClass}
                            disabled={selectedFeeType === ''}
                            onChange={(event) => setSelectedClass(event.target.value)}
                        >
                            {classSection.length ? classSection.map(eachClass => <MenuItem key={eachClass.standard} value={eachClass.standard}>{eachClass.standard}</MenuItem>) : null}
                        </Select>
                    </FormControl></Grid>
                    <Grid item><FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                        <InputLabel id="select-section-label">Section</InputLabel>
                        <Select
                            labelId="select-section-label"
                            label="Section"
                            value={selectedSection}
                            disabled={selectedClass === ''}
                            onChange={(event) => setSelectedSection(event.target.value)}
                        >
                            {classSection.filter(eachClass => eachClass.standard === selectedClass)?.[0]?.section.sort().map(eachSection => <MenuItem key={eachSection} value={eachSection}>{eachSection}</MenuItem>)}
                        </Select>
                    </FormControl></Grid>
                    <Grid item>
                        <Button sx={{
                            margin: '10px'
                        }}
                            onClick={resetFilter}
                            startIcon={<FilterAltOffRoundedIcon />}
                            variant="contained"
                        >
                            Reset Filter
                        </Button>
                    </Grid>
                </Grid>
                <Divider sx={{
                    margin: (theme) => theme.spacing(2)
                }} />
                <Grid container spacing={3}>
                    <Overview feetype={selectedFeeType} term={selectedTerm} standard={selectedClass} section={selectedSection} />

                    <Grid item xs={12} md={6} lg={8}>
                        <BarChart feetype={selectedFeeType} term={selectedTerm} standard={selectedClass} section={selectedSection} />
                    </Grid>

                </Grid>
            </Container>
        </>
    );
}