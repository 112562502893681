import PropTypes from 'prop-types';
import { alpha, styled } from '@mui/material/styles';
import { Card, Typography, CardActions } from '@mui/material';
import { fCurrency } from '../../utils/formatNumber';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import HourglassTopRoundedIcon from '@mui/icons-material/HourglassTopRounded';
import PaymentDialog from '../../components/paymentdialog';


const StyledIcon = styled('div')(({ theme }) => ({
    margin: 'auto',
    display: 'flex',
    borderRadius: '50%',
    alignItems: 'center',
    width: theme.spacing(8),
    height: theme.spacing(8),
    justifyContent: 'center',
    marginBottom: theme.spacing(3),
}));


PaymentWidget.propTypes = {
    color: PropTypes.string,
    icon: PropTypes.string,
    term: PropTypes.string.isRequired,
    amount: PropTypes.number.isRequired,
    feeId: PropTypes.string.isRequired,
    transactionId: PropTypes.string,
    sx: PropTypes.object,
};

export default function PaymentWidget({ term, amount, icon, feeId, transactionId, color = 'primary', sx, ...other }) {
    return (
        <Card
            sx={{
                py: 5,
                boxShadow: 0,
                textAlign: 'center',
                color: (theme) => theme.palette[color].darker,
                bgcolor: (theme) => theme.palette[color].lighter,
                ...sx,
            }}
            {...other}
        >
            <StyledIcon
                sx={{
                    color: (theme) => theme.palette[color].dark,
                    backgroundImage: (theme) =>
                        `linear-gradient(135deg, ${alpha(theme.palette[color].dark, 0)} 0%, ${alpha(
                            theme.palette[color].dark,
                            0.24
                        )} 100%)`,
                }}
            >
                {color === 'success' && <CheckRoundedIcon fontSize='large' />}
                {color === 'error' && <ClearRoundedIcon fontSize='large' />}
                {color === 'info' && <HourglassTopRoundedIcon fontSize='large' />}
            </StyledIcon>

            <Typography variant="h3">{fCurrency(amount)}</Typography>

            <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
                {term}
            </Typography>
            <CardActions>
                <PaymentDialog color={color} feeId={feeId} term={term} amount={amount} transactionId={transactionId} />
            </CardActions>
        </Card>
    );
}
