import axios from 'axios';
import { getCookie } from '../../base/token';
import config from '../../config';


const isProduction = process.env.NODE_ENV === 'production';

const axiosInstance = axios.create({
    baseURL: isProduction ? config.production.baseURL : config.development.baseURL
});

axiosInstance.interceptors.request.use(
    config => {
        const token = getCookie('token');
        if (token) {
            config.headers['Authorization'] = token;
        }
        return config;
    },
    error => {
        return Promise.reject(error.message);
    }
);

axiosInstance.interceptors.response.use(
    response => response,
    error => {
        console.log(error, 'Error in Instance');
        if (error.response) {
            if (error.response?.data?.message) {
                error = error.response.data.message;
            } else if (error.response.data?.error) {
                error = error.response.data.error;
            } else if (error.response.statusText) {
                error = error.response.statusText;
            }
        }
        return Promise.reject(error);
    }
)

export default axiosInstance;