import { Alert } from "@mui/material";
import '../styles.css'



export default function DonwloadSampleFile() {
    const href = window.location.pathname.includes('staff') ? 'https://anish-project-data.s3.amazonaws.com/staff-details.xlsx'
        : 'https://anish-project-data.s3.amazonaws.com/student-details.xlsx';

    return (
        <>
            <Alert severity="info">Please download the sample file and change the values and keep it ready before going to the next step. If you are already done with the changes please donwload the sample file and compare the header once to avoid any issues in the data upload.</Alert>
            <a className="displayFlex" style={{ marginTop: '20px' }} href={href}>Download Sample File</a>
        </>
    );
};
