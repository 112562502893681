import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactApexChart from 'react-apexcharts';
import { Card, CardHeader, Box, ToggleButtonGroup, ToggleButton, Stack } from '@mui/material';
import { useChart } from '../../../../components/chart';
import { fCurrency } from '../../../../utils/formatNumber';
import { getTimeIntervalPayments } from '../../../../service/dashboardService';

BarChart.propTypes = {
  feetype: PropTypes.string,
  term: PropTypes.string,
  standard: PropTypes.string,
  section: PropTypes.string
};

export default function BarChart({ feetype, term, standard, section, ...other }) {

  const [timeInterval, setTimeInterval] = useState('monthly');
  const [data, setData] = useState({});
  const chartLabels = Object.keys(data),
    chartData =
      [
        {
          name: 'Total fees paid',
          type: 'column',
          fill: 'solid',
          data: Object.values(data),
        }];

  useEffect(() => {
    const fetchData = async () => {
      const response = await getTimeIntervalPayments({
        timeInterval: timeInterval,
        ...(feetype && Object.keys(feetype).length ? { feetype } : {}),
        ...(term && Object.keys(term).length ? { term } : {}),
        ...(standard && Object.keys(standard).length ? { standard } : {}),
        ...(section && Object.keys(section).length ? { section } : {})
      });
      setData(response.data);
    }
    fetchData();
  }, [timeInterval, feetype, term, standard, section])

  const chartOptions = useChart({
    plotOptions: { bar: { columnWidth: '16%' } },
    fill: { type: chartData.map((i) => i.fill) },
    labels: chartLabels,
    xaxis: { type: 'string' },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (y) => {
          if (typeof y !== 'undefined') {
            return `${fCurrency(y)}`;
          }
          return y;
        },
      },
    },
  });

  return (
    <Card {...other}>
      <Stack direction='row' sx={{
        justifyContent: 'space-between'
      }}>
        <CardHeader title='Payment Details' subheader='Payment details by month / day' />
        <ToggleButtonGroup sx={{
          margin: '2%'
        }}
          color="primary"
          value={timeInterval}
          exclusive
          onChange={(e) => setTimeInterval(e.target.value)}
          aria-label="timeInterval"
        >
          {/* <ToggleButton value="yearly">Yearly</ToggleButton> */}
          <ToggleButton value="monthly">Monthly</ToggleButton>
          <ToggleButton value="daily">Daily</ToggleButton>
        </ToggleButtonGroup>
      </Stack>
      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        <ReactApexChart type="line" series={chartData} options={chartOptions} height={364} />
      </Box>
    </Card >
  );
}
