import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';

import Widgets from '../../components/paymentwidgets';
import DialogBox from '../../components/dialogbox';
import ChipDivider from '../../components/chipdivider/ChipDivider';
import PaymentResponseDialog from '../../components/paymentresponsedialog';

import { Container, Grid, Typography } from '@mui/material';

import { getFeeDetails } from '../../service/paymentService';
import { styled } from '@mui/material/styles';
import { FormattedMessage } from 'react-intl';
import { getUserDetails } from '../../base/userDetails';


export default function PaymentPage() {
    const [paymentResponse, setPaymentResponse] = useState(null);
    const [paidFees, setPaidFees] = useState(null);
    const [unpaidFees, setUnpaidFees] = useState(null);
    const [pendingFees, setPendingFees] = useState(null);
    const [error, setError] = useState(null);
    const userDetails = getUserDetails();

    useEffect(() => {
        setError(null);
        const queryParameters = new URLSearchParams(window.location.search);
        if (queryParameters.size === 3)
            setPaymentResponse({ orderId: queryParameters.get('order_id'), status: queryParameters.get('status'), amount: queryParameters.get('amount') });
        const fetchData = async () => {
            try {
                const response = await getFeeDetails(userDetails.class, userDetails.admissionNumber);
                if (response.data?.feeDetails.length === 0) {
                    setError({ action: 'Close', message: 'No fees are pending' });
                }
                setPaidFees(response.data.feeDetails.filter((eachFee) => eachFee.status === 'Paid'));
                setUnpaidFees(response.data.feeDetails.filter((eachFee) => eachFee.status === 'Not Paid'));
                setPendingFees(response.data.feeDetails.filter((eachFee) => eachFee.status === 'Pending'));
            } catch (error) {
                setError(error.toString());
            }
        };
        fetchData();
    }, [userDetails.class, userDetails.admissionNumber]);

    const StyledDivider = styled('div')(({ theme }) => ({
        margin: theme.spacing(3)
    }));

    return (
        <>
            <Helmet>
                <title>Payment | Page</title>
            </Helmet>
            <Container maxWidth="xl">
                {error && <DialogBox content={error} />}
                {paymentResponse && <PaymentResponseDialog paymentResponse={paymentResponse} />}
                {(unpaidFees && unpaidFees.length) ?
                    (<>
                        <StyledDivider>
                            <ChipDivider label={
                                <Typography variant="body2">
                                    <FormattedMessage
                                        id="Not-Paid"
                                    />
                                </Typography>
                            } />
                        </StyledDivider>
                        < Grid container spacing={3}>
                            {unpaidFees.map((eachFee) => {
                                return (
                                    <Grid item xs={12} sm={6} md={3} key={eachFee.feeId}>
                                        <Widgets color='error' term={eachFee.feeName} amount={parseInt(eachFee.amount.$numberDecimal)} feeId={eachFee.feeId} />
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </>) : ''
                }
                {(pendingFees && pendingFees.length) ?
                    (<>
                        <StyledDivider>
                            <ChipDivider label={
                                <Typography variant="body2">
                                    <FormattedMessage
                                        id="Pending-status"
                                    />
                                </Typography>
                            } />
                        </StyledDivider>
                        < Grid container spacing={3}>
                            {pendingFees.map((eachFee) => {
                                return (
                                    <Grid item xs={12} sm={6} md={3} key={eachFee.feeId}>
                                        <Widgets color='info' term={eachFee.feeName} amount={parseInt(eachFee.amount.$numberDecimal)} feeId={eachFee.feeId} />
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </>) : ''
                }
                {(paidFees && paidFees.length) ?
                    (<>
                        <StyledDivider>
                            <ChipDivider label={
                                <Typography variant="body2">
                                    <FormattedMessage
                                        id="Paid"
                                    />
                                </Typography>
                            } />
                        </StyledDivider>
                        < Grid container spacing={3}>
                            {paidFees.map((eachFee) => {
                                return (
                                    <Grid item xs={12} sm={6} md={3} key={eachFee.feeId}>
                                        <Widgets color='success' term={eachFee.feeName} amount={parseInt(eachFee.amount.$numberDecimal)} feeId={eachFee.feeId} transactionId={eachFee.transactionId} />
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </>) : ''
                }
            </Container >
        </>
    );
}
