import React, { forwardRef, useState, useEffect } from 'react';
import { Paper, Container, Typography, Box, Divider, Stack, Grid, Card } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import logo from '../../logo/ssbmvLogo.png';
import { getPaymentDetails } from '../../service/feeReceipt'
import { FormattedMessage } from 'react-intl';
import { fCurrency } from '../../utils/formatNumber';

const PdfPaper = styled(Paper)(({ theme }) => ({
    width: '210mm',
    padding: theme.spacing(2),
    margin: '0 auto',
    background: '#fff',
    boxShadow: theme.shadows[3],
    overflowY: 'auto',
}));

const StyledDivider = styled(Divider)(({ theme }) => ({
    margin: theme.spacing(1)
}));

const StyledHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2, 2.5),
    margin: theme.spacing(2),
    borderRadius: Number(theme.shape.borderRadius) * 1.5,
    backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));


const feeReceiptSlip = forwardRef(
    (
        {
            transactionId
        },
        ref
    ) => {
        const [paymentDetails, setPaymentDetails] = useState(null);

        useEffect(() => {
            const fetchFeeDetails = async () => {
                const details = await getPaymentDetails(transactionId);
                if (details)
                    setPaymentDetails(details.data[0]);
            };
            fetchFeeDetails();
        }, [transactionId]);

        const formatReadableDate = (isoString) => {
            const inputDate = isoString;
            const [month, day, year] = inputDate.split('/');
            const date = new Date (`${day}-${month}-${year}`);
            const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' };
            return date.toLocaleDateString('en-US', options);
        }

        return (
            <>
                <PdfPaper ref={ref}>
                    <Container sx={{
                        border: '3px',
                        borderStyle: 'solid',
                        borderColor: '#0E4286',
                        borderRadius: '10px'
                    }}>
                        <StyledHeader sx={{ marignTop: '10%' }}>
                            <Box sx={{ height: '150px', width: 'auto', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <img src={logo} alt="" style={{ maxWidth: '100%', maxHeight: '100%' }} />
                            </Box>
                            <Box sx={{ ml: 2, paddingLeft: '5%' }}>
                                <Typography variant="h4" sx={{ color: 'text.primary' }}>
                                    Sri Suka Brahma Maharishi Vidyalam
                                </Typography>
                                <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                                    Matriculation Higher Secondary School
                                </Typography>
                                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                    Peedampalli, Coimbatore - 16.
                                </Typography>
                            </Box>
                        </StyledHeader>

                        <Card align='center' sx={{ padding: '0 20% 0 20%', border: '2px', borderStyle: 'solid', borderColor: (theme) => theme.palette.grey[200], margin: '3%' }}>
                            <Box sx={{ margin: '2%' }}>
                                <Typography variant='h2'><FormattedMessage id='fee-receipt'/></Typography>
                                <StyledDivider variant='middle' />
                                <Stack direction='row' alignItems='center'>
                                    <Grid item xs={15}>
                                        <Typography variant='h10'><FormattedMessage id="Name"/> :</Typography>
                                    </Grid>
                                    <Grid item xs={15}>
                                        <Typography variant='h4'>{paymentDetails?.billing_name}</Typography>
                                    </Grid>
                                </Stack>
                                <StyledDivider variant='middle' />
                                <Stack direction='row' alignItems='center'>
                                    <Grid item xs={15}>
                                        <Typography variant='h10'><FormattedMessage id="Amount"/> :</Typography>
                                    </Grid>
                                    <Grid item xs={15}>
                                        <Typography variant='h4'>{fCurrency(Number(paymentDetails?.amount.$numberDecimal))}</Typography>
                                    </Grid>
                                </Stack>
                                <StyledDivider variant='middle' />
                                <Stack direction='row' alignItems='center'>
                                    <Grid item xs={15}>
                                        <Typography variant='h10'><FormattedMessage id="payment-status"/> :</Typography>
                                    </Grid>
                                    <Grid item xs={15}>
                                        <Typography variant='h4'>{paymentDetails?.order_status}</Typography>
                                    </Grid>
                                </Stack>
                                <StyledDivider variant='middle' />
                                <Stack direction='row' alignItems='center'>
                                    <Grid item xs={15}>
                                        <Typography variant='h10'><FormattedMessage id="class"/> :</Typography>
                                    </Grid>
                                    <Grid item xs={15}>
                                        <Typography variant='h4'>{paymentDetails?.merchant_param4}</Typography>
                                    </Grid>
                                </Stack>
                                <StyledDivider variant='middle' />
                                <Stack direction='row' alignItems='center'>
                                    <Grid item xs={15}>
                                        <Typography variant='h10'><FormattedMessage id='Term'/> :</Typography>
                                    </Grid>
                                    <Grid item xs={15}>
                                        <Typography variant='h4'>{paymentDetails?.merchant_param2}</Typography>
                                    </Grid>
                                </Stack>
                                <StyledDivider variant='middle' />
                                <Stack direction='row' alignItems='center'>
                                    <Grid item xs={15}>
                                        <Typography variant='h10'><FormattedMessage id='order-id'/> :</Typography>
                                    </Grid>
                                    <Grid item xs={15}>
                                        <Typography variant='h8'>{paymentDetails?.order_id}</Typography>
                                    </Grid>
                                </Stack>
                                <StyledDivider variant='middle' />
                                <Stack direction='row' alignItems='center'>
                                    <Grid item xs={15}>
                                        <Typography variant='h10'><FormattedMessage id='date-time'/> :</Typography>
                                    </Grid>
                                    <Grid item xs={15}>
                                        <Typography variant='h8'>{paymentDetails ? formatReadableDate(paymentDetails.paymentResponse.trans_date) : ''}</Typography>
                                    </Grid>
                                </Stack>
                            </Box>
                        </Card>
                        <Box sx={{ margin: '2%', paddingLeft: '7px' }}>
                            <Typography variant='body2'><FormattedMessage id='pdf-note'/></Typography>
                        </Box>
                    </Container>
                </PdfPaper >
            </>
        );
    });

export default feeReceiptSlip;