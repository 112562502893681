import { useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  Button,
  Card,
  CardContent,
  CardMedia,
  ToggleButton,
  ToggleButtonGroup,
  Alert,
  Divider,
  Backdrop,
  CircularProgress
} from "@mui/material";
import logo from "../../../logo/ssbmvLogo.png";
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { setCookie } from '../../../base/token';
import { verifyLogin, getStudent } from '../../../service/loginService';
import { setUserDetails } from "../../../base/userDetails";
import { FormattedMessage } from "react-intl";
import { Crisp } from "crisp-sdk-web";
import OtpDialog from "../../../components/otpdialog";


const LoginCard = () => {
  return (
    <>
      <div>
        <Helmet>
          <title> Login | Page </title>
        </Helmet>

        <Card variant="outlined" sx={{ maxWidth: "md" }}>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} md={5} sx={{ marginRight: '5%' }}>
                <LogoBlock />
              </Grid>
              <Divider orientation="vertical" variant="middle" flexItem />
              <Grid item xs={12} md={6}>
                <SignInBlock />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </div>
    </>
  );
};

const LogoBlock = () => {
  return (
    <>
      <CardMedia component="img" src={logo} alt="Logo" />
    </>
  );
};


const SignInBlock = () => {

  const [role, setRole] = useState("STUDENT");
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState();
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [backDrop, setBackDrop] = useState(false);
  const [openOTPDialog, setOpenOTPDialog] = useState(false);
  const [email, setEmail] = useState('');
  const [secretKey, setSecretKey] = useState('');


  const navigate = useNavigate();

  const handleChange = (event, newRole) => {
    if (newRole) setRole(newRole);
  };

  const handleEnter = (event) => {
    setError(false);
    if (event.key === "Enter") {
      handleLogin(event);
    }
  };


  const handleLogin = async (e) => {
    e.preventDefault();
    setError(false);
    setBackDrop(true);
    try {
      const response = await verifyLogin(userName, password, role);
      if (response && response.data && response.data.success) {
        if (response?.data?.message?.otp) {
          setEmail(response.data.message.email);
          setSecretKey(response.data.message.secretKey);
          setOpenOTPDialog(true);
          setBackDrop(false);
          return;
        }
        setCookie('token', response.data.message);
        let details = null;
        if (role === 'STUDENT') {
          details = await getStudent(userName);
          Crisp.session.setData({
            user_id: userName,
            plan: "free"
          });
          Crisp.setTokenId(userName);
          Crisp.user.setNickname(details?.data?.students[0]?.firstName + ' ' + details?.data?.students[0]?.lastName);
          details = {
            ...details?.data?.students[0],
            userName: details?.data?.students[0]?.firstName + ' ' + details?.data?.students[0]?.lastName,
            role: role
          };
        } else {
          Crisp.setTokenId(userName);
          Crisp.user.setEmail(userName);
          details = {
            userName: userName,
            role: role
          }
        }
        setUserDetails(details);
        navigate('/dashboard', { replace: true });
        setBackDrop(false);
      }
    } catch (error) {
      setError(true);
      setErrorMessage(
        error.code === "ERR_NETWORK" ? "Network Error" : error
      );
      setBackDrop(false);
    }
  };

  return (
    <>
      <div style={{ padding: "5%" }}>
        <div style={{ padding: "5% 0 5% 0" }}>
          <ToggleButtonGroup
            color="primary"
            value={role}
            exclusive
            onChange={handleChange}
            aria-label="Platform"
          >
            <ToggleButton value="ADMIN">ADMIN</ToggleButton>
            <ToggleButton value="STAFF">STAFF</ToggleButton>
            <ToggleButton value="STUDENT">STUDENT</ToggleButton>
          </ToggleButtonGroup>
        </div>
        <Typography
          variant="h4"
          align="center"
          color="primary"
          sx={{ paddingBottom: "1%" }}
          gutterBottom
        >
          Sign in
        </Typography>
        <TextField
          label={role === "STUDENT" ? "User Name" : "Email"}
          variant="outlined"
          fullWidth
          margin="normal"
          onChange={(event) => {
            setUserName(event.target.value);
          }}
          onKeyDown={handleEnter}
        />
        <TextField
          label="Password"
          type="password"
          variant="outlined"
          fullWidth
          margin="normal"
          onChange={(event) => {
            setPassword(event.target.value);
          }}
          onKeyDown={handleEnter}
        />
        {error && (
          <Alert
            severity="error"
            sx={{ backgroundColor: "#FFB4A4", fontWeight: "bold" }}
            onClose={() => {
              setError(false);
            }}
          >
            {errorMessage}
          </Alert>
        )}
        <Button
          variant="contained"
          color="primary"
          fullWidth
          sx={{ marginTop: "2%" }}
          onClick={handleLogin}
        >
          <FormattedMessage id="login" />
        </Button>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={backDrop}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      {openOTPDialog && (
        <OtpDialog openDialog={openOTPDialog} onCloseDialog={() => setOpenOTPDialog(false)} email={email} secretKey={secretKey} userName={userName} role={role} />
      )}
    </>
  );
};



export default LoginCard;
