import axios from '../axios';

export function verifyLogin(userName, password, role) {
    return axios.post('/userDetails/verifyUser', { userName, password, role });
}

export function getStudent(admissionNumber) {
    const headers = {
        params: {
            query: { admissionNumber: admissionNumber },
            pageNumber: 1,
        }
    }
    return axios.get('/studentDetails/fetchStudents', headers);
}

export function verifyotp(otp, token) {
    return axios.post('/userDetails/verifyotp', { otp, token });
}

export function resendotp(token) {
    return axios.post('/userDetails/resendotp', { token })
}