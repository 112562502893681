import { Dialog, DialogContent, Button, Typography, Card, Divider, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import { useState } from 'react';
import { styled } from '@mui/material/styles';
import { fCurrency } from '../../utils/formatNumber';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

PaymentResponseDialog.propTypes = {
    paymentResponse: PropTypes.object.isRequired
}


const StyledButton = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.error.dark,
    marginTop: theme.spacing(1),
    color: 'white',
    '&:hover': {
        transform: 'scale(1.05)',
        color: theme.palette.error.dark,
        borderColor: theme.palette.error.dark
    }
}));

const StyledStack = styled(Stack)(({ theme }) => ({
    margin: theme.spacing(1)
}))


export default function PaymentResponseDialog({ paymentResponse }) {
    const [open, setOpen] = useState(true);
    const navigate = useNavigate();
    const handleClose = (e) => {
        e.preventDefault();
        setOpen(false);
        navigate('/dashboard/payment');
    }

    const color = paymentResponse.status.toLowerCase() === 'success' ? 'success' : 'error'

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}>
                <DialogContent>
                    <Stack align='center'>
                        <Stack>
                            {color === 'success' ? < CheckCircleRoundedIcon
                                sx={{
                                    color: (theme) => theme.palette[color].dark,
                                    fontSize: '400%',
                                    alignSelf: 'center'
                                }} /> : <ErrorRoundedIcon
                                sx={{
                                    color: (theme) => theme.palette[color].dark,
                                    fontSize: '400%',
                                    alignSelf: 'center'
                                }} />}
                        </Stack>
                        <Stack>
                            <Typography variant='h4'>{paymentResponse.status}</Typography>
                        </Stack>
                        <Card>
                            <StyledStack>
                                <Typography varaint='body2'> Order ID: {paymentResponse.orderId}</Typography>
                            </StyledStack>
                            <Divider variant='middle' />
                            <StyledStack>
                                <Typography varaint='body2'><FormattedMessage id="Amount"/>: {fCurrency(paymentResponse.amount)}</Typography>
                            </StyledStack>
                        </Card>
                        <Stack>
                            <StyledButton fullWidth onClick={handleClose}><FormattedMessage id="close"/></StyledButton>
                        </Stack>
                    </Stack>
                </DialogContent>
            </Dialog>
        </>
    )
}