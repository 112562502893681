import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Button,
  Card,
  Checkbox,
  Chip,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
import Scrollbar from '../scrollbar';
import { getInitials } from '../../utils/getInitials';
import { displayStatus } from '../../utils/constants';


export const PageTable = (props) => {
  PageTable.propTypes = {
    count: PropTypes.number,
    items: PropTypes.array,
    onDeselectAll: PropTypes.func,
    onDeselectOne: PropTypes.func,
    onPageChange: PropTypes.func,
    onRowsPerPageChange: PropTypes.func,
    onSelectAll: PropTypes.func,
    onSelectOne: PropTypes.func,
    deleteOne: PropTypes.func,
    page: PropTypes.number,
    rowsPerPage: PropTypes.number,
    selected: PropTypes.array,
    displayNA: PropTypes.bool
  };
  const {
    count = 0,
    items = [],
    multiSelect,
    onDeselectAll,
    onDeselectOne,
    onPageChange = () => { },
    onRowsPerPageChange,
    onSelectAll,
    onSelectOne,
    deleteOne,
    page = 0,
    rowsPerPage = 0,
    selected = [],
    headerValues = {},
    displayNA = false,
  } = props;

  const selectedSome = (selected.length > 0) && (selected.length < items.length);
  const selectedAll = (items.length > 0) && (selected.length === items.length);

  return (
    <Card>
      <Scrollbar>
        <Box sx={{ minWidth: 500 }}>
          <Table>
            <TableHead>
              <TableRow>
                {multiSelect ?
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedAll}
                      indeterminate={selectedSome}
                      onChange={(event) => {
                        if (event.target.checked) {
                          onSelectAll?.();
                        } else {
                          onDeselectAll?.();
                        }
                      }}
                    />
                  </TableCell> : ''}
                {Object.keys(headerValues).map((key) => (
                  <TableCell key={key}>{headerValues[key]}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {items.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={Object.keys(headerValues).length} align="center">
                    <Typography variant="body1">No data available</Typography>
                  </TableCell>
                </TableRow>
              ) : (
                items.map((eachData) => {
                  const isSelected = selected.includes(eachData.id);

                  return (
                    <TableRow
                      hover
                      key={eachData.id}
                      selected={isSelected}
                    >
                      {multiSelect ?
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isSelected}
                            onChange={(event) => {
                              if (event.target.checked) {
                                onSelectOne?.(eachData.id);
                              } else {
                                onDeselectOne?.(eachData.id);
                              }
                            }}
                          />
                        </TableCell> : ''}
                      {Object.keys(headerValues).map((key) => (
                        <TableCell key={key} sx={{ backgroundColor: !eachData[key] && !displayNA ? 'red' : ''  }}>
                          <Stack
                            alignItems="center"
                            direction="row"
                            spacing={2}
                          >
                            {key === 'name' ? (
                              <Avatar>{getInitials(eachData[key])}</Avatar>
                            ) : null}
                            {key === 'delflag' ? (
                              <Button onClick={() => {
                                deleteOne?.(eachData.admissionNumber);
                              }}>Delete</Button>
                            ) : null}
                            {key === 'chips' ? (
                              <Chip sx={{width: '75px'}} color={displayStatus['Paid'].includes(eachData[key]) ? 'success' : displayStatus['Pending'].includes(eachData[key]) ? 'warning' : 'error'} label={eachData[key]}></Chip>
                            ) : null}
                            <Typography>{key !== 'chips' && eachData[key] ? eachData[key] : displayNA ? 'NA' : ''}</Typography>
                          </Stack>
                        </TableCell>
                      ))}
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </Box>
      </Scrollbar>
      {count ?
        <TablePagination
          component="div"
          count={count}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        /> : ''}
    </Card>
  );
};