import React from 'react';
import ThemeProvider from '../theme';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
// routes
import Router from './routes';
// components
import { StyledChart } from '../components/chart';
import ScrollToTop from '../components/scroll-to-top';
import { LanguageProvider } from './language/LanguageContext';
import CrispChat from './crispChat/CrispChat';


// ----------------------------------------------------------------------

export default function App() {
  return (
    <LanguageProvider>
      <HelmetProvider>
        <BrowserRouter>
          <ThemeProvider>
            <ScrollToTop />
            <StyledChart />
            <Router />
            <div>
              <CrispChat />
            </div>
          </ThemeProvider>
        </BrowserRouter>
      </HelmetProvider>
    </LanguageProvider>
  );
}
