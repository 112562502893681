import { Card, CardActionArea, Typography } from '@mui/material';
import { PropTypes } from 'prop-types';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';
const StyledIcon = styled('div')(({ theme }) => ({
    display: 'flex',
    borderRadius: '50%',
    alignItems: 'center',
    width: theme.spacing(10),
    height: theme.spacing(10),
    justifyContent: 'center',
    marginBottom: theme.spacing(3),
}));

DashboardWidgets.propTypes = {
    cardDetails: PropTypes.object.isRequired
}

export default function DashboardWidgets({ cardDetails }) {
    const navigate = useNavigate();
    const handleRedirect = () => {
        navigate(cardDetails.path, { replace: true });
    }

    return (
        <>
            <Card sx={{
                '&:hover': {
                    transform: 'scale(1.05)'
                }
            }}>
                <CardActionArea onClick={handleRedirect}>
                    <Typography variant='h4' sx={{
                        margin: '3%',
                        textTransform: 'capitalize'
                    }}>
                        {cardDetails.title}
                    </Typography>
                    <StyledIcon
                        sx={{
                            paddingTop: '3%',
                            marginBottom: '0%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                            zoom: '300%',
                            height: '50%',
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        {cardDetails.icon}
                    </StyledIcon>
                    <NavigateNextRoundedIcon sx={{
                        marginLeft: '90%',
                        marginTop: '10%'
                    }} />
                </CardActionArea>
            </Card>
        </>
    )
}