import React from 'react';
import { Container, Typography, Grid, Paper, TextField, Button } from '@mui/material';
import DropdownField from '../../components/dropdownfield';
import { Formik, Form, Field } from 'formik';
import { Helmet } from 'react-helmet-async';
import { getUserDetails } from '../../base/userDetails';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

const optionsForGender = [
    { value: 'Male', label: 'Male' },
    { value: 'Female', label: 'Female' },
    { value: 'Other', label: 'Other' }
];

const UserDetails = () => {
    const initialValues = getUserDetails();
    const handleSubmit = (values) => {
        console.log(values);
    };
    const navigate = useNavigate();
    const handleCancel = (event) => {
        event.preventDefault();
        navigate('/dashboard/app', { replace: true })
    }

    return (
        <>
            <Helmet>
                <title>
                    User Details | Page
                </title>
            </Helmet>
            <Container maxWidth="md">
                <Typography variant="h4" align="center" gutterBottom>
                    <FormattedMessage id="user-details"/>
                </Typography>
                <Paper sx={{ padding: '1rem' }}>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={handleSubmit}
                    >
                        {({ dirty }) => (
                            <Form>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={6}>
                                        <Field
                                            name="firstName"
                                            as={TextField}
                                            label={<FormattedMessage id="firstName"/>}
                                            // label="First Name"
                                            fullWidth
                                            margin="normal"
                                            disabled
                                        />
                                        <Field
                                            name="lastName"
                                            as={TextField}
                                            label={<FormattedMessage id="lastName"/>}
                                            fullWidth
                                            margin="normal"
                                            disabled
                                        />
                                        <Field
                                            name="dateOfBirth"
                                            as={TextField}
                                            type="date"
                                            label={<FormattedMessage id="dateOfBirth"/>}
                                            fullWidth
                                            margin="normal"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled
                                        />
                                        <DropdownField
                                            name="gender"
                                            label={<FormattedMessage id="gender"/>}
                                            options={optionsForGender}
                                            fullWidth
                                            margin="normal"
                                            disabled
                                        />
                                        <Field
                                            name="religion"
                                            as={TextField}
                                            label={<FormattedMessage id="religion"/>}
                                            fullWidth
                                            margin="normal"
                                            disabled
                                        />
                                        <Field
                                            name="caste"
                                            as={TextField}
                                            label={<FormattedMessage id="caste"/>}
                                            fullWidth
                                            margin="normal"
                                            disabled
                                        />
                                        <Field
                                            name="community"
                                            as={TextField}
                                            label={<FormattedMessage id="community"/>}
                                            fullWidth
                                            margin="normal"
                                            disabled
                                        />
                                        <Field
                                            name="dateOfAdmission"
                                            as={TextField}
                                            type="date"
                                            label={<FormattedMessage id="dateOfAdmission"/>}
                                            fullWidth
                                            margin="normal"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            disabled
                                        />
                                        <Field
                                            name="fatherName"
                                            as={TextField}
                                            label={<FormattedMessage id="fatherName"/>}
                                            fullWidth
                                            margin="normal"
                                            disabled
                                        />
                                        <Field
                                            name="motherName"
                                            as={TextField}
                                            label={<FormattedMessage id="motherName"/>}
                                            fullWidth
                                            margin="normal"
                                            disabled
                                        />
                                        <Field
                                            name="parentsOccupation"
                                            as={TextField}
                                            label={<FormattedMessage id="parentsOccupation"/>}
                                            fullWidth
                                            margin="normal"
                                            disabled
                                        />
                                        <Field
                                            name="parentsAnnualIncome"
                                            as={TextField}
                                            label={<FormattedMessage id="parentsAnnualIncome"/>}
                                            fullWidth
                                            margin="normal"
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Field
                                            name="motherTongue"
                                            as={TextField}
                                            label={<FormattedMessage id="motherTongue"/>}
                                            fullWidth
                                            margin="normal"
                                            disabled
                                        />
                                        <Field
                                            name="aadharNumber"
                                            as={TextField}
                                            label={<FormattedMessage id="aadharNumber"/>}
                                            fullWidth
                                            margin="normal"
                                            disabled
                                        />
                                        <Field
                                            name="fatherPhone"
                                            as={TextField}
                                            label={<FormattedMessage id="fatherPhone"/>}
                                            fullWidth
                                            margin="normal"
                                            disabled
                                        />
                                        <Field
                                            name="address"
                                            as={TextField}
                                            label={<FormattedMessage id="address"/>}
                                            fullWidth
                                            margin="normal"
                                            disabled
                                        />
                                        <Field
                                            name="city"
                                            as={TextField}
                                            label={<FormattedMessage id="city"/>}
                                            fullWidth
                                            margin="normal"
                                            disabled
                                        />
                                        <Field
                                            name="state"
                                            as={TextField}
                                            label={<FormattedMessage id="state"/>}
                                            fullWidth
                                            margin="normal"
                                            disabled
                                        />
                                        <Field
                                            name="pinCode"
                                            as={TextField}
                                            label={<FormattedMessage id="pinCode"/>}
                                            fullWidth
                                            margin="normal"
                                            disabled
                                        />
                                        <Field
                                            name="class"
                                            as={TextField}
                                            label={<FormattedMessage id="class"/>}
                                            fullWidth
                                            margin="normal"
                                            disabled
                                        />
                                        <Field
                                            name="section"
                                            as={TextField}
                                            label={<FormattedMessage id="section"/>}
                                            fullWidth
                                            margin="normal"
                                            disabled
                                        />
                                        <Field
                                            name="email"
                                            as={TextField}
                                            label={<FormattedMessage id="email"/>}
                                            fullWidth
                                            margin="normal"
                                            disabled
                                        />
                                        <Field
                                            name="admissionNumber"
                                            as={TextField}
                                            label={<FormattedMessage id="admissionNumber"/>}
                                            fullWidth
                                            margin="normal"
                                            disabled
                                        />
                                        <Field
                                            name="bloodGroup"
                                            as={TextField}
                                            label={<FormattedMessage id="bloodGroup"/>}
                                            fullWidth
                                            margin="normal"
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Button type="submit" variant="contained" color="primary" style={{ marginTop: '1rem' }} fullWidth disabled={!dirty}>
                                        <FormattedMessage id="update"/>
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Button onClick={handleCancel} variant="contained" color="primary" style={{ marginTop: '1rem' }} fullWidth>
                                            <FormattedMessage id="cancel"/>
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </Paper>
            </Container >
        </>
    );
};

export default UserDetails;
