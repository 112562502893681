import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Alert, Backdrop, CircularProgress } from '@mui/material';
import { error_details } from '../../../utils/constants';
import { PageTable } from '../../../components/table/pageTable';
import { useSelection } from '../../../hooks/use-selection';
import { applyPagination } from '../../../utils/applyPagination';
import { createStaffStudents } from '../../../service/userService';

const useStaffStudents = (page, rowsPerPage, data) => {
    return useMemo(
        () => {
            if (data && data.value) {
                return applyPagination(data.value, page, rowsPerPage);
            } else {
                return [];
            }
        },
        [page, rowsPerPage, data]
    );
};

const useUserIds = (staffStudent) => {
    return useMemo(
        () => {
            return staffStudent.map((user) => user.id);
        },
        [staffStudent]
    );
};

export const BulkUploadResults = (props) => {
    const { staffStudentData, role } = props;
    const [loader, setLoader] = useState(false);
    const [errorData, setErrorData] = useState(null);
    const [errorValidate, setErrorValidate] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [headerValues, setHeaderValues] = useState(null);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const staffStudent = useStaffStudents(page, rowsPerPage, errorData);
    const staffStudentIds = useUserIds(staffStudent);
    const staffStudentSelection = useSelection(staffStudentIds);

    // useEffect(() => {
    //     staffStudentData?.(errorFields, fileResult);
    // }, [errorFields, fileResult, staffStudentData])

    useEffect(() => {
        const createStudentsApi = () => {
            return new Promise(async (resolve, reject) => {
                try {
                    setErrorValidate('');
                    setSuccessMessage('');
                    setLoader(true);
                    let payload = [];
                    let url = '/studentDetails/createStudents';
                    if (role === 'staff') {
                        url = '/staffDetails/createStaffs';
                    } else {
                        url = '/studentDetails/createStudents';
                    }
                    Object.keys(staffStudentData).forEach((eachKey) => {
                        payload.push(staffStudentData[eachKey]);
                    });
                    payload = _.flattenDeep(payload);
                    const response = await createStaffStudents(url, payload);
                    resolve(response);
                } catch (error) {
                    reject(error);
                }
            });
        };
        createStudentsApi()
            .then((result) => {
                setLoader(false);
                if (result && result.data && result.data.success) {
                    setSuccessMessage('success');
                } else {
                    if (result && result.data && result.data.error && result.data.error.length) {
                        let erroredData = [];
                        result.data.error.forEach((eachError) => {
                            eachError.users.forEach((eachUser, index) => {
                                const obj = {};
                                obj.id = index;
                                obj.s_no = index + 1;
                                obj.error = eachError.error;
                                obj.user = eachUser;
                                erroredData.push(obj);
                            });
                        })
                        setErrorData(erroredData);
                    }
                }
                setHeaderValues(error_details);
            })
            .catch((error) => {
                console.log('Error: ', error)
                setErrorValidate('Something went wrong. Please try again later.');
            });
        // eslint-disable-next-line
    }, [staffStudentData]);

    const handlePageChange = (event, value) => {
        setPage(value);
    }

    const handleRowsPerPageChange = async (event) => {
        setRowsPerPage(event.target.value);
    }

    return (
        <>
            {errorValidate !== '' ? <Alert severity="error">{errorValidate}</Alert> : ''}
            {successMessage === 'success' ? <Alert severity="success">The {role}s have been uploaded successfully</Alert> : ''}
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loader}>
                <CircularProgress color="inherit" />
            </Backdrop>
            {errorData && errorData.length ?
                <PageTable
                    count={errorData.length}
                    items={errorData}
                    onDeselectAll={staffStudentSelection.handleDeselectAll}
                    onDeselectOne={staffStudentSelection.handleDeselectOne}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleRowsPerPageChange}
                    onSelectAll={staffStudentSelection.handleSelectAll}
                    onSelectOne={staffStudentSelection.handleSelectOne}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    selected={staffStudentSelection.selected}
                    headerValues={headerValues}
                /> : ''}
        </>
    );
};

BulkUploadResults.propTypes = {
    staffStudentData: PropTypes.object,
    role: PropTypes.string
};

export default BulkUploadResults;