import { Avatar, Card, CardContent, Stack, Typography, Grid } from '@mui/material';
import Pie from "../pie";
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { getOverviewDetails } from '../../../../service/dashboardService';
import { fNumber, fCurrency } from '../../../../utils/formatNumber';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import HourglassTopRoundedIcon from '@mui/icons-material/HourglassTopRounded';

Overview.propTypes = {
  feetype: PropTypes.string,
  term: PropTypes.string,
  standard: PropTypes.string,
  section: PropTypes.string
};



export default function Overview({ feetype, term, standard, section }) {

  const theme = useTheme();

  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await getOverviewDetails({
        ...(feetype && Object.keys(feetype).length ? { feetype } : {}),
        ...(term && Object.keys(term).length ? { term } : {}),
        ...(standard && Object.keys(standard).length ? { standard } : {}),
        ...(section && Object.keys(section).length ? { section } : {})
      });
      setData(response.data);
    }
    fetchData();
  }, [feetype, term, standard, section])


  return (
    <>
      <Grid item xs={6} sm={6} lg={3}>
        <Card>
          <CardContent>
            <Stack
              alignItems="flex-start"
              direction="row"
              justifyContent="space-between"
              spacing={3}
            >
              <Stack spacing={1}>
                <Typography
                  variant="overline"
                >
                  Total Students
                </Typography>
                <Typography variant="h4">
                  {fNumber(data?.reduce((accumulator, current) => accumulator + current.totalCount, 0))}
                </Typography>
                <Typography variant="h8">
                  {fCurrency(data?.reduce((accumulator, current) => accumulator + current.totalAmount, 0))}
                </Typography>
              </Stack>
              <Avatar
                sx={{
                  height: 75,
                  width: 75
                }}
              >
                <AddRoundedIcon fontSize='large' />
              </Avatar>
            </Stack>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={6} sm={6} lg={3}>
        <Card>
          <CardContent>
            <Stack
              alignItems="flex-start"
              direction="row"
              justifyContent="space-between"
              spacing={3}
            >
              <Stack spacing={1}>
                <Typography
                  variant="overline"
                >
                  Total Paid
                </Typography>
                <Typography variant="h4">
                  {fNumber((data.find(each => each._id === 'Paid') || {}).totalCount || 0)}
                </Typography>
                <Typography variant="h8" color={theme.palette.success.dark}>
                  {fCurrency((data.find(each => each._id === 'Paid') || {}).totalAmount || 0)}
                </Typography>
              </Stack>
              <Avatar
                sx={{
                  height: 75,
                  width: 75
                }}
              >
                <CheckRoundedIcon fontSize='large' />
              </Avatar>
            </Stack>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={6} sm={6} lg={3}>
        <Card>
          <CardContent>
            <Stack
              alignItems="flex-start"
              direction="row"
              justifyContent="space-between"
              spacing={3}
            >
              <Stack spacing={1}>
                <Typography
                  variant="overline"
                >
                  Total Not Paid
                </Typography>
                <Typography variant="h4">
                  {fNumber((data.find(each => each._id === 'Not Paid') || {}).totalCount || 0)}
                </Typography>
                <Typography variant="h8" color={theme.palette.error.dark}>
                  {fCurrency((data.find(each => each._id === 'Not Paid') || {}).totalAmount || 0)}
                </Typography>
              </Stack>
              <Avatar
                sx={{
                  height: 75,
                  width: 75
                }}
              >
                <ClearRoundedIcon fontSize='large' />
              </Avatar>
            </Stack>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={6} sm={6} lg={3}>
        <Card>
          <CardContent>
            <Stack
              alignItems="flex-start"
              direction="row"
              justifyContent="space-between"
              spacing={3}
            >
              <Stack spacing={1}>
                <Typography
                  variant="overline"
                >
                  Pending Status
                </Typography>
                <Typography variant="h4">
                  {fNumber((data.find(each => each._id === 'Pending') || {}).totalCount || 0)}
                </Typography>
                <Typography variant="h8" color={theme.palette.info.dark}>
                  {fCurrency((data.find(each => each._id === 'Pending') || {}).totalAmount || 0)}
                </Typography>
              </Stack>
              <Avatar
                sx={{
                  height: 75,
                  width: 75
                }}
              >
                <HourglassTopRoundedIcon fontSize='large' />
              </Avatar>
            </Stack>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <Pie
          title="Percentage distribution"
          subheader="Percentage of students between each status"
          chartData={[
            { label: 'Paid', value: (data?.find(each => each._id === 'Paid') || {}).totalCount || 0 },
            { label: 'Pending Status', value: (data?.find(each => each._id === 'Pending') || {}).totalCount || 0 },
            { label: 'Not Paid', value: (data?.find(each => each._id === 'Not Paid') || {}).totalCount || 0 },
            { label: 'Default', value: (data?.find(each => each._id === 'Default') || {}).totalCount || 0 }
          ]}
          chartColors={[
            theme.palette.success.main,
            theme.palette.info.main,
            theme.palette.error.main,
            theme.palette.warning.main
          ]}
        />
      </Grid>
    </>
  );
};
