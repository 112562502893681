import { Helmet } from 'react-helmet-async';
import StudentStaffLayout from '../../layouts/studentstaff';



export default function StudentsPage() {

  return (
    <>
      <Helmet>
        <title>
          Students | Page
        </title>
      </Helmet>
      <StudentStaffLayout role={'student'} />
    </>
  );
};
