import PaymentIcon from '@mui/icons-material/Payment';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import SchoolRoundedIcon from '@mui/icons-material/SchoolRounded';
import AccountBoxRoundedIcon from '@mui/icons-material/AccountBoxRounded';
import AdminPanelSettingsRoundedIcon from '@mui/icons-material/AdminPanelSettingsRounded';
import LeaderboardRoundedIcon from '@mui/icons-material/LeaderboardRounded';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import QueryStatsRoundedIcon from '@mui/icons-material/QueryStatsRounded';
import { FormattedMessage } from 'react-intl';

// ----------------------------------------------------------------------

const navConfig = [
  {
    title: 'home',
    path: '/dashboard/app',
    icon: <HomeRoundedIcon />,
    access: ['ADMIN', 'STUDENT', 'STAFF']
  },
  {
    title: <FormattedMessage id='user' />,
    path: '/dashboard/user',
    icon: <AccountBoxRoundedIcon />,
    access: ['STAFF', 'STUDENT']
  },
  {
    title: <FormattedMessage id='payment' />,
    path: '/dashboard/payment',
    icon: <PaymentIcon />,
    access: ['STUDENT']
  },
  {
    title: 'students',
    path: '/dashboard/students',
    icon: <SchoolRoundedIcon />,
    access: ['ADMIN', 'STAFF']
  },
  {
    title: 'staffs',
    path: '/dashboard/staffs',
    icon: <AdminPanelSettingsRoundedIcon />,
    access: ['ADMIN']
  },
  {
    title: 'dashboard',
    path: '/dashboard/dashboard',
    icon: <LeaderboardRoundedIcon />,
    access: ['ADMIN']
  },
  {
    title: 'Payment Details',
    path: '/dashboard/paymentDetails',
    icon: <AccountBalanceWalletIcon />,
    access: ['ADMIN', 'STAFF']
  },
  {
    title: 'Audit',
    path: '/dashboard/audit',
    icon: <QueryStatsRoundedIcon />,
    access: ['ADMIN', 'STAFF']
  }
];

export default navConfig;
