import { Helmet } from "react-helmet-async";
import { Grid, FormControl, Divider, Button, Container, Alert, Card, Typography, Snackbar, Checkbox, TextField, Autocomplete } from "@mui/material";
import { useEffect, useState } from "react";
import { getPaymentMethods, getPaymentDetails } from "../../service/audit";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FilterAltOffRoundedIcon from '@mui/icons-material/FilterAltOffRounded';
import StickyHeaderTable from "../../components/stickyheadertable";
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import DownloadIcon from '@mui/icons-material/Download';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { fCurrency } from "../../utils/formatNumber";
import * as XLSX from 'xlsx';
import _ from 'lodash';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function AuditPage() {
    const [paymentModes, setPaymentModes] = useState([]);
    const [selectedPaymentModes, setSelectedPaymentModes] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [rows, setRows] = useState([]);


    useEffect(() => {
        const paymentModes = async () => {
            const response = await getPaymentMethods();
            if (response) {
                setPaymentModes(response.data);
            }
        }
        paymentModes();
    }, []);

    const onPaymentModeChange = (event, value) => {
        setError(false);
        setSelectedPaymentModes(value);
    }

    const onStartDateChange = (event) => {
        setError(false);
        setStartDate(event.toDate());
    }

    const onEndDateChange = (event) => {
        if (_.isNull(startDate)) {
            setError(true);
            setErrorMessage('Please select start date');
        }
        else if (event.toDate() < startDate) {
            setError(true);
            setErrorMessage('End date should be equal or greater then start date');
        }
        else {
            setError(false);
            setEndDate(event.toDate());
        }
    }

    const startSearch = async () => {
        console.log(startDate, endDate, paymentModes);
        if (startDate && endDate && paymentModes) {
            const paymentDetails = await getPaymentDetails(selectedPaymentModes, startDate, endDate);
            setRows(paymentDetails.data);
        } else {
            setError(true);
            setErrorMessage('Please select payment mode, and date-range');
        }
    }

    const resetFilter = () => {
        setSelectedPaymentModes([]);
        setStartDate(null);
    }

    const columns = [
        { id: 'billing_name', label: 'Name', minWidth: 100 },
        { id: 'amount', label: 'Amount', minWidth: 80 },
        { id: 'merchant_param4', label: 'Class', minWidth: 20, align: 'right' },
        {
            id: 'merchant_param2',
            label: 'Term Details',
            minWidth: 50,
            align: 'right', format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'finalDate',
            label: 'Transaction Date',
            minWidth: 100,
            align: 'right', format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'paymentMode',
            label: 'Payment Mode',
            minWidth: 150,
            align: 'right', format: (value) => value.toLocaleString('en-US'),
        }
    ];

    const exportToExcel = () => {
        if (!selectedPaymentModes || !rows.length || !startDate) {
            setError(true);
            setErrorMessage('Plase select payment method and date. Also press search and then export.');
            return;
        }

        const sheetData = rows.map((eachRow) => {
            return {
                name: eachRow.billing_name,
                amount: eachRow.amount,
                class: eachRow.merchant_param4,
                termDetails: eachRow.merchant_param2,
                transactionDate: eachRow.finalDate,
                paymentMode: eachRow.paymentMode
            }
        });
        const ws = XLSX.utils.json_to_sheet(sheetData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        XLSX.writeFile(wb, `${startDate.getDate() + '-' + (startDate.getMonth() + 1)}.xlsx`);
    }




    return (
        <>
            <Helmet>
                <title>
                    Audit | Page
                </title>
            </Helmet>
            <Container>
                <Grid container spacing={2} sx={{ alignItems: 'center' }}>
                    <Grid item>
                        <FormControl sx={{ m: 1, minWidth: 150 }} size="medium">
                            <Autocomplete
                                multiple
                                id="select-paymentmode-label"
                                options={paymentModes}
                                disableCloseOnSelect
                                getOptionLabel={(option) => option}
                                renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {option}
                                    </li>
                                )}
                                style={{ width: 500 }}
                                value={selectedPaymentModes}
                                onChange={onPaymentModeChange}
                                renderInput={(params) => (
                                    <TextField {...params} label="Payment Mode" placeholder="Payment Mode" />
                                )}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label="Start Date"
                                format="DD/MM/YYYY"
                                value={startDate}
                                onError={() => { setError(error); setErrorMessage('Error in start date') }}
                                onChange={(event) => onStartDateChange(event)}
                                sx={{}}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label="End Date"
                                format="DD/MM/YYYY"
                                value={endDate}
                                onError={() => { setError(error); setErrorMessage('Error in end date') }}
                                onChange={(event) => onEndDateChange(event)}
                                sx={{}}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item>
                        <Button
                            onClick={resetFilter}
                            startIcon={<FilterAltOffRoundedIcon />}
                            variant="contained"
                        >
                            Reset Filter
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            onClick={startSearch}
                            startIcon={<ManageSearchIcon />}
                            variant="contained"
                        >
                            seacrh
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            onClick={exportToExcel}
                            startIcon={<DownloadIcon />}
                            variant="contained"
                        >
                            Download
                        </Button>
                    </Grid>
                </Grid >
                {error && (
                    <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={error} autoHideDuration={2000} onClose={() => { setError(false) }}>
                        <Alert
                            severity="error"
                            sx={{ backgroundColor: "#FFB4A4", fontWeight: "bold" }}
                            onClose={() => {
                                setError(false);
                            }}
                        >
                            {errorMessage}
                        </Alert>
                    </Snackbar>
                )}
                <Divider sx={{
                    margin: (theme) => theme.spacing(2)
                }} />
                {rows.length === 0 ? (<></>) :
                    (<>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Card sx={{ m: 1, backgroundColor: (theme) => theme.palette.grey[300] }}>
                                    <Typography variant='h4' sx={{
                                        margin: '3%',
                                        textTransform: 'capitalize'
                                    }}>
                                        Total Payments : {rows.length}
                                    </Typography>
                                </Card>
                            </Grid>
                            <Grid item xs={6}>
                                <Card sx={{ m: 1, backgroundColor: (theme) => theme.palette.grey[300] }}>
                                    <Typography variant='h4' sx={{
                                        margin: '3%',
                                        textTransform: 'capitalize'
                                    }}>
                                        Total Amount : {fCurrency(rows.reduce((total, obj) => total + Number(obj?.amount?.['$numberDecimal']), 0))}
                                    </Typography>
                                </Card>
                            </Grid>
                        </Grid>
                        <Divider sx={{
                            margin: (theme) => theme.spacing(2)
                        }} />
                    </>
                    )}
                <StickyHeaderTable columns={columns} rows={rows.map((eachRow) => { eachRow.amount = eachRow?.amount?.$numberDecimal; _.set(eachRow, `paymentMode`, eachRow?.paymentResponse?.payment_mode); return eachRow })} />
            </Container >
        </>
    )
}