import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import DownloadIcon from '@mui/icons-material/Download';
import UploadIcon from '@mui/icons-material/Upload';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import AddIcon from '@mui/icons-material/Add';
import { Backdrop, Box, Button, CircularProgress, Container, InputLabel, MenuItem, Select, Stack, SvgIcon, Typography } from '@mui/material';
import { PageTable } from '../../components/table/pageTable';
import './styles.css';
import { deleteStudent, fetchStaff, fetchStudent } from '../../service/userService';
import { classes, staffTableHeader, studentTableHeader } from '../../utils/constants';
import { section } from '../../utils/constants';
import DialogBox from '../../components/dialogbox/DialogBox';
import { AddMultiUser } from './components/addMultiUser';


StudentStaffLayout.propTypes = {
    role: PropTypes.string.isRequired
}


export default function StudentStaffLayout({ role }) {
    const fetchData = useCallback(async (classe, section, row, pages) => {
        try {
            let response = null;
            const fetchPayload = {
                params: {
                    pageNumber: pages + 1,
                    pageSize: row
                }
            }
            if (role === 'student') {
                fetchPayload.params['query'] = { class: classe, section: section };
                response = await fetchStudent(fetchPayload);
                setHeader(studentTableHeader);
                formatStudentResponse(response);
            }
            if (role === 'staff') {
                fetchPayload.params['query'] = {};
                response = await fetchStaff(fetchPayload);
                setHeader(staffTableHeader);
                formatStaffResponse(response);
            }
            setLoader(false);
        } catch (error) {
            setError(error.toString());
            setLoader(false);
        }
        // eslint-disable-next-line
    }, []);
    const [count, setCount] = useState(0);
    const [data, setData] = useState([]);
    const [page, setPage] = useState(0);
    const [error, setError] = useState(null);
    const [loader, setLoader] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [classValue, setClassValue] = useState('LKG');
    const [sections, setSection] = useState('A');
    const [headers, setHeader] = useState({});
    const [addMultiple, setAddMultiple] = useState(() => false);

    useEffect(() => {
        if (role === 'student') {
            setHeader(studentTableHeader);
        } else if (role === 'staff') {
            setHeader(staffTableHeader);
        }
        setLoader(true);
        fetchData(classValue, sections, rowsPerPage, page);
        // eslint-disable-next-line
    }, [fetchData, rowsPerPage, page]);

    const formatStudentResponse = (response) => {
        let sampData = [];
        if (response && response.data && response.data.students && response.data.students.length) {
            response.data.students.forEach((eachStudent, index) => {
                const obj = {};
                obj.name = `${eachStudent.firstName} ${eachStudent.lastName}`;
                obj.class = `${eachStudent.class} - ${eachStudent.section}`;
                obj.address = eachStudent.address;
                obj.gender = eachStudent.gender;
                obj.admissionNumber = eachStudent.admissionNumber;
                obj.id = index;
                obj.delflag = true;
                sampData.push(obj);
            })
            setData(sampData);
        } else {
            setData([]);
        }
        if (response?.data?.totalCount) {
            setCount(response.data.totalCount);
        } else {
            setCount(0);
        }
    }

    const formatStaffResponse = (response) => {
        let sampData = [];
        if (response && response.data && response.data.staffs && response.data.staffs.length) {
            response.data.staffs.forEach((eachStaff, index) => {
                const obj = {};
                obj.name = `${eachStaff.firstName} ${eachStaff.lastName}`;
                obj.qualification = eachStaff.qualification;
                obj.address = eachStaff.address;
                obj.gender = eachStaff.gender;
                obj.employeeId = eachStaff.employeeId;
                obj.id = index;
                sampData.push(obj);
            })
            setData(sampData);
        } else {
            setData([]);
        }
        if (response?.data?.totalCount) {
            setCount(response.data.totalCount);
        } else {
            setCount(0)
        }
    }


    const handleClassChange = (event) => {
        setClassValue(event.target.value);
    }

    const handleSectionChange = (event) => {
        setSection(event.target.value);
    }

    const handlePageChange = (event, value) => {
        setPage(value);
    }

    const handleRowsPerPageChange = async (event) => {
        setRowsPerPage(event.target.value);
    }

    const handleFindUsers = () => {
        fetchData(classValue, sections, rowsPerPage, page);
    }

    const handleAddUsers = () => {
    }

    const handleAddMultiple = () => {
        setAddMultiple((prevAddMultiple) => !prevAddMultiple);
    }

    const handleCloseDialog = () => {
        setAddMultiple((prevAddMultiple) => !prevAddMultiple);
    }

    const handleDelete = (admissionNumber) => {
        const payload = { params: { admissionNumber: admissionNumber } };
        deleteStudent(payload);
    }

    return (
        <>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    py: 8,
                    paddingTop: 0
                }}
            >
                <Container maxWidth="xl">
                    {error ? <DialogBox content={error} /> : ''}

                    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loader}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    <Stack spacing={3}>
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            spacing={4}
                        >
                            <Stack spacing={1}>
                                <Typography variant="h4">
                                    {role === 'staff' ? 'Staffs' : 'Students'}
                                </Typography>
                                {role === 'student' ?
                                    <Stack
                                        alignItems="center"
                                        direction="row"
                                        spacing={1}
                                    >
                                        <InputLabel>Class</InputLabel>
                                        <Select
                                            displayEmpty
                                            id="classes-select"
                                            value={classValue}
                                            onChange={handleClassChange}
                                            size="small"
                                        >
                                            {classes.map((item, index) => (
                                                <MenuItem key={index} value={item}>
                                                    {item}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <InputLabel>Section</InputLabel>
                                        <Select
                                            displayEmpty
                                            id="section-select"
                                            value={sections}
                                            onChange={handleSectionChange}
                                            size='small'
                                        >
                                            {section.map((item, index) => (
                                                <MenuItem key={index} value={item}>
                                                    {item}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <Button
                                            onClick={handleFindUsers}
                                            startIcon={(
                                                <SvgIcon fontSize="small">
                                                    <ManageSearchIcon />
                                                </SvgIcon>
                                            )}
                                            variant="contained"
                                        >
                                            Find {role === 'staff' ? ' Staffs' : ' Students'}
                                        </Button>
                                    </Stack>
                                    : ''
                                }
                            </Stack>
                            <div className='displayFlex'>
                                <Button
                                    onClick={handleAddUsers}
                                    startIcon={(
                                        <SvgIcon fontSize="small">
                                            <AddIcon />
                                        </SvgIcon>
                                    )}
                                    variant="contained"
                                >
                                    Add {role === 'staff' ? ' Staffs' : ' Students'}
                                </Button>
                                <Stack
                                    direction='row'
                                    alignItems='center'
                                    justifyContent='flex-end'
                                    sx={{
                                        paddingTop: '10px'
                                    }}
                                >
                                    <Button
                                        onClick={() => handleAddMultiple()}
                                        color="inherit"
                                        startIcon={(
                                            <SvgIcon fontSize="small">
                                                <UploadIcon />
                                            </SvgIcon>
                                        )}
                                    >
                                        Import
                                    </Button>
                                    <Button
                                        color="inherit"
                                        startIcon={(
                                            <SvgIcon fontSize="small">
                                                <DownloadIcon />
                                            </SvgIcon>
                                        )}
                                    >
                                        Export
                                    </Button>
                                </Stack>
                            </div>
                        </Stack>
                        <PageTable
                            count={count}
                            items={data}
                            onPageChange={handlePageChange}
                            onRowsPerPageChange={handleRowsPerPageChange}
                            deleteOne={handleDelete}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            headerValues={headers}
                        />
                    </Stack>
                    {addMultiple ?
                        <AddMultiUser open={addMultiple} onCloseDialog={handleCloseDialog} role={role} />
                        : ''}
                </Container>
            </Box>
        </>
    )
}