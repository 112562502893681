import * as React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Slide from '@mui/material/Slide';
import { deleteCookie } from '../../base/token';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

DialogBox.propTypes = {
    content: PropTypes.any.isRequired,
};


export default function DialogBox({ content }) {
    const [open, setOpen] = useState(true);
    const navigate = useNavigate();
    const handleLogOut = (e) => {
        e.preventDefault();
        if (content?.action === 'Close') {
            setOpen(false);
        }
        else {
            deleteCookie('token');
            navigate('/login', { replace: true });
        }
    }

    return (
        <>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                fullWidth={true}
            >
                <DialogTitle>
                    {content?.action === 'Close' ? 'Info' : 'Error'}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {typeof (content) === 'object' ? content.message : JSON.stringify(content)}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleLogOut}>
                        {content?.action === 'Close' ? 'Close' : 'Log out'}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}