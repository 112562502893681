import React, { useEffect, useMemo, useState } from 'react';
import * as XLSX from 'xlsx/xlsx.mjs';
import PropTypes from 'prop-types';
import { Alert, Backdrop, CircularProgress, Tab, Tabs } from '@mui/material';
import { import_staff_details, import_student_details } from '../../../utils/constants';
import { PageTable } from '../../../components/table/pageTable';
import { useSelection } from '../../../hooks/use-selection';
import { applyPagination } from '../../../utils/applyPagination';


const useStaffStudents = (page, rowsPerPage, data) => {
    return useMemo(
        () => {
            if (data && data.value) {
                return applyPagination(data.value, page, rowsPerPage);
            } else {
                return [];
            }
        },
        [page, rowsPerPage, data]
    );
};

const useUserIds = (staffStudent) => {
    return useMemo(
        () => {
            return staffStudent.map((user) => user.id);
        },
        [staffStudent]
    );
};



export const ExcelValidateFormat = (props) => {
    const { excelFileData, staffStudentData } = props;
    const [fileResult, setFileResult] = useState([]);
    const [loader, setLoader] = useState(false);
    const [errorFields, setErrorFields] = useState([]);
    const [selectedSheet, setSelectedSheet] = useState(null);
    const [errorValidate, setErrorValidate] = useState('');
    const [headerValues, setHeaderValues] = useState(null);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const staffStudent = useStaffStudents(page, rowsPerPage, selectedSheet);
    const staffStudentIds = useUserIds(staffStudent);
    const staffStudentSelection = useSelection(staffStudentIds);

    useEffect(() => {
        staffStudentData?.(errorFields, fileResult);
    }, [errorFields, fileResult, staffStudentData])

    useEffect(() => {
        const fileReader = () => {
            return new Promise((resolve, reject) => {
                try {
                    setLoader(true);
                    const oFile = excelFileData;
                    const reader = new FileReader();

                    reader.onload = (e) => {
                        const data = e.target.result;
                        const cfb = XLSX.read(data, { type: 'binary' });
                        const result = {};

                        cfb.SheetNames.forEach((sheetName) => {
                            const oJS = XLSX.utils.sheet_to_row_object_array(cfb.Sheets[sheetName]);
                            result[sheetName] = oJS;
                        });

                        resolve(result);
                    };

                    reader.onerror = (error) => {
                        reject(error);
                    };

                    reader.readAsBinaryString(oFile);
                } catch (error) {
                    reject(error);
                }
            });
        };

        fileReader()
            .then((result) => {
                setLoader(false);
                setFileResult(result);
                formatData(result);
                validateData(result);
            })
            .catch((error) => {
                console.log('Error: ', error)
                setErrorValidate('Error occured while reading the file. Please upload the file and try again');
            });
        // eslint-disable-next-line
    }, [excelFileData]);

    const formatData = (data) => {
        const sheets = Object.keys(data);
        const obj = {};
        obj.key = sheets[0];
        obj.value = data[obj.key];
        setSelectedSheet(obj);
    };

    const validateData = (data) => {
        const sheets = Object.keys(data);
        let errorField = [];
        const headerValue = window.location.pathname.includes('staff')
            ? import_staff_details
            : import_student_details;
        setHeaderValues(headerValue);
        sheets.forEach((eachSheet) => {
            const eachSheetData = data[eachSheet];
            eachSheetData.forEach((eachData, index) => {
                eachData.id = index;
                Object.keys(headerValue).forEach((eachKey) => {
                    if (eachData[eachKey] && typeof eachData[eachKey] === 'string') {
                        eachData[eachKey] = eachData[eachKey].trim();
                    }
                    if (!eachData[eachKey]) {
                        errorField.push(eachData[eachKey]);
                    }
                });
            });
        });
        setErrorFields(errorField);
    };

    const handleTabChange = (event, newValue) => {
        setSelectedSheet(newValue);
    };

    const handlePageChange = (event, value) => {
        setPage(value);
    }

    const handleRowsPerPageChange = async (event) => {
        setRowsPerPage(event.target.value);
    }

    return (
        <>
            {errorFields && errorFields.length ? <Alert severity="error">Error in the file. Please compare the header and values with the sample file and try again.</Alert> : ''}
            {errorValidate !== '' ? <Alert severity="error">{errorValidate}</Alert> : ''}
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loader}>
                <CircularProgress color="inherit" />
            </Backdrop>
            {selectedSheet ? (
                <Tabs
                    value={selectedSheet.key}
                    onChange={handleTabChange}
                    variant="scrollable"
                    scrollButtons
                    allowScrollButtonsMobile
                    aria-label="scrollable force tabs example"
                >
                    {Object.keys(fileResult).map((eachSection) => (
                        <Tab key={eachSection} label={eachSection} value={eachSection} />
                    ))}
                </Tabs>
            ) : null}
            {selectedSheet && selectedSheet.value && selectedSheet.value.length ?
                <PageTable
                    count={staffStudent.length}
                    items={staffStudent}
                    onDeselectAll={staffStudentSelection.handleDeselectAll}
                    onDeselectOne={staffStudentSelection.handleDeselectOne}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleRowsPerPageChange}
                    onSelectAll={staffStudentSelection.handleSelectAll}
                    onSelectOne={staffStudentSelection.handleSelectOne}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    selected={staffStudentSelection.selected}
                    headerValues={headerValues}
                    multiSelect={true}
                /> : ''}
        </>
    );
};

ExcelValidateFormat.propTypes = {
    excelFileData: PropTypes.object,
    staffStudentData: PropTypes.func
};

export default ExcelValidateFormat;