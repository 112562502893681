import { Helmet } from 'react-helmet-async';
import StudentStaffLayout from '../../layouts/studentstaff';



export default function StaffsPage() {

  return (
    <>
      <Helmet>
        <title>
          Staff | Page
        </title>
      </Helmet>
      <StudentStaffLayout role={'staff'} />
    </>
  );
};
