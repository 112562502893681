import React, { createContext, useState } from 'react';
import { IntlProvider } from 'react-intl';
import enMessages from '../../languages/en.json';
import taMessages from '../../languages/ta.json';

const messages = {
    en: enMessages,
    ta: taMessages,
  };

const LanguageContext = createContext();

const LanguageProvider = ({ children }) => {
  const [locale, setLocale] = useState('en');

  return (
    <LanguageContext.Provider value={{ locale, setLocale }}>
      <IntlProvider locale={locale} messages={messages[locale]}>
        {children}
      </IntlProvider>
    </LanguageContext.Provider>
  );
};

export { LanguageContext, LanguageProvider };