import React from 'react';
import './LoginPage.css';
import LoginCard from './LoginCard/LoginCard'

const LoginPage = () => {
    return (
        <div className="login-background">
            <LoginCard />
        </div>
    );
};

export default LoginPage;