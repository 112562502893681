import PropTypes from 'prop-types';
import { Divider, Chip } from '@mui/material';

ChipDivider.propTypes = {
    label: PropTypes.any
}

export default function ChipDivider({ label }) {
    return (<>
        <Divider>
            <Chip sx={{
                backgroundColor: (theme) => theme.palette.grey[500],
                color: (theme) => theme.palette.grey[100],
                fontWeight: '100',
                fontSize: 'large'
            }}
                label={label} size='medium' />
        </Divider>
    </>)
}