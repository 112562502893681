import { Fragment, forwardRef, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { Dialog, IconButton, Slide } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DonwloadSampleFile from './downloadSampleFile';
import { FileUploadStudentStaff } from './fileUploadStudentStaff';
import { ExcelValidateFormat } from './excelValidateFormat';
import BulkUploadResults from './bulkUploadResults';


const steps = ['Dowload sample file', 'Upload the file', 'Validate the file', 'Results'];


const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const AddMultiUser = (props) => {

  AddMultiUser.propTypes = {
    open: PropTypes.bool,
    onCloseDialog: PropTypes.func,
    role: PropTypes.string
  }
  const {
    open = false,
    onCloseDialog,
    role
  } = props;
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const [fileData, setFileData] = useState(null);
  const [disableNext, setDisableNext] = useState(false);
  const [staffStudentData, setStaffStudentData] = useState(null);
  const [stepFailed, setStepFailed] = useState(false);


  const isStepOptional = (step) => {
    return step === 0;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
    handleCloseDialog();
  };

  const handleCloseDialog = () => {
    onCloseDialog?.(!open);
  }

  const handleUploadData = (data) => {
    setFileData(data);
  }

  const handleStaffStudentData = (error, data) => {
    isStepFailed(error, 2);
    if (!error.length) {
      setStaffStudentData(data);
    } else {
      setStaffStudentData(null);
    }
  }

  const isStepFailed = (error) => {
    if (activeStep === 2 && error && error.length) {
      setStepFailed(true);
    } else {
      setStepFailed(false);
    }
  }

  useEffect(() => {
    switch (activeStep) {
      case 1: {
        if (!fileData) {
          setDisableNext(true);
          break;
        } else {
          setDisableNext(false);
          break;
        }
      }
      case 2: {
        if (!staffStudentData) {
          setDisableNext(true);
        } else {
          setDisableNext(false);
        }
        break;
      }
      default: {
        setDisableNext(false);
      }
    }
    // eslint-disable-next-line
  }, [activeStep, fileData, staffStudentData])

  return (
    <Box sx={{ width: '100%', maxWidth: 'none' }}>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        fullWidth
        maxWidth='lg'
        id="dialog-element"
      >
        <IconButton
          edge="start"
          color="inherit"
          onClick={handleCloseDialog}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
        <Stepper sx={{ padding: '10px' }} activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            if (isStepOptional(index)) {
              labelProps.optional = (
                <Typography variant="caption">Optional</Typography>
              );
            }
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            if (index === 2 && stepFailed) {
              labelProps.optional = (
                <Typography variant="caption" color="error">
                  Oops! Error in the file.
                </Typography>
              );

              labelProps.error = true;
            }
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        {activeStep === steps.length ? (
          handleCloseDialog()
        ) : (
          <Fragment>
            {!activeStep ? <Box sx={{ mt: 2, mb: 1, padding: '2 0px' }}><DonwloadSampleFile /></Box> : ''}
            {activeStep === 1 ? <Box sx={{ mt: 2, mb: 1, padding: '2 0px' }}><FileUploadStudentStaff fileData={handleUploadData} /></Box> : ''}
            {activeStep === 2 && fileData ? <Box sx={{ mt: 2, mb: 1, padding: '2 0px' }}><ExcelValidateFormat excelFileData={fileData} staffStudentData={handleStaffStudentData} /></Box> : ''}
            {activeStep === 3 ? <Box sx={{ mt: 2, mb: 1, padding: '2 0px' }}><BulkUploadResults staffStudentData={staffStudentData} role={role} /></Box> : ''}
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              {activeStep !== 3 ?
                <Button
                  color="inherit"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                >
                  Back
                </Button> : ''}
              <Box sx={{ flex: '1 1 auto' }} />
              {isStepOptional(activeStep) && (
                <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                  Skip
                </Button>
              )}

              <Button onClick={handleNext} disabled={disableNext}>
                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
              </Button>
            </Box>
          </Fragment>
        )}
      </Dialog>
    </Box>
  );
}