import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Dialog, Container, Button, Grid, Typography, Avatar, Divider, DialogActions, SvgIcon, Box, Stack, Card, AppBar, Toolbar } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { intiatePayment } from '../../service/paymentService';
import { getUserDetails } from '../../base/userDetails';
import CreditCardRoundedIcon from '@mui/icons-material/CreditCardRounded';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import GetAppRoundedIcon from '@mui/icons-material/GetAppRounded';
import BlockRoundedIcon from '@mui/icons-material/BlockRounded';
import LockRoundedIcon from '@mui/icons-material/LockRounded';
import { styled, alpha } from '@mui/material/styles';
import { fCurrency } from '../../utils/formatNumber';
import { FormattedMessage } from 'react-intl';
import CloseIcon from '@mui/icons-material/Close';
import LocalPrintshopRoundedIcon from '@mui/icons-material/LocalPrintshopRounded';
import { useReactToPrint } from 'react-to-print';
import FeeReceiptSlip from '../feesreceiptslip';

const StyledButton = styled(Button)(({ theme, color }) => ({
    backgroundColor: theme.palette[color].dark,
    color: 'white',
    width: '50%',
    '&:hover': {
        transform: 'scale(1.05)',
        color: theme.palette[color].dark,
        borderColor: theme.palette[color].dark
    },
    '&:disabled': {
        backgroundColor: theme.palette.action.disabled,
        color: theme.palette.text.disabled
    }
}));

const StlyedDialogButton = styled(Button)(({ theme, color }) => ({
    color: theme.palette[color].lighter,
    backgroundColor: theme.palette[color].darker,
    fontWeight: 'fontWeightBold',
    margin: '0 10% 0 10%',
    boxShadow: theme.customShadows.z16,
    '&:hover': {
        transform: 'scale(1.1)',
        color: theme.palette[color].lighter,
        backgroundColor: theme.palette[color].darker
    }
}));

const StyledAccount = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2, 2.5),
    margin: theme.spacing(2),
    borderRadius: Number(theme.shape.borderRadius) * 1.5,
    backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

const StyledDivider = styled(Divider)(({ theme }) => ({
    margin: theme.spacing(1)
}));


ResponsiveDialog.propTypes = {
    color: PropTypes.string.isRequired,
    feeId: PropTypes.string.isRequired,
    term: PropTypes.string.isRequired,
    amount: PropTypes.number.isRequired,
    transactionId: PropTypes.string
}

export default function ResponsiveDialog({ color, feeId, term, amount, transactionId }) {
    const [open, setOpen] = useState(false);
    const [openPDF, setOpenPDF] = useState(false);
    const userDetails = getUserDetails();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));
    const receipt = useRef();

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    };

    const handleClosePDF = () => {
        setOpenPDF(false);
    }

    const handlePrint = useReactToPrint({
        content: () => receipt.current
    });

    const triggerPayment = async () => {
        if (color === 'success') {
            setOpenPDF(true);
        }
        else if (color === 'error') {
            const response = await intiatePayment(userDetails.class, userDetails.admissionNumber, feeId);
            window.location.replace(`${response.data.url}&encRequest=${response.data.encRequest}&access_code=${response.data.accessCode}`);
        }
    }

    amount = fCurrency(amount);

    return (
        <>
            <StlyedDialogButton
                size="large"
                fullWidth
                color={color}
                onClick={handleOpen}>{color === 'error' && <FormattedMessage id="pay-now" />} {color === 'success' && <FormattedMessage id="download-receipt" />} {color === 'info' && <FormattedMessage id="view-details" />}
            </StlyedDialogButton>

            <Dialog
                open={openPDF}
                fullScreen>

                <FeeReceiptSlip
                    ref={receipt}
                    transactionId={transactionId}
                    handlePrint={handlePrint}
                    sx={{
                        height: '100%'
                    }}
                />
                <AppBar sx={{ position: 'relative', backgroundColor: (theme) => theme.palette.grey[200] }}>
                    <Toolbar>
                        <StyledButton
                            color="success"
                            fullWidth
                            startIcon={<LocalPrintshopRoundedIcon />}
                            onClick={handlePrint}
                            sx={{
                                margin: '2%'
                            }}
                        >
                            <FormattedMessage id='print' />
                        </StyledButton>
                        <StyledButton
                            color="error"
                            fullWidth
                            startIcon={<CloseIcon />}
                            onClick={handleClosePDF}
                            sx={{
                                margin: '2%'
                            }}
                        >
                            <FormattedMessage id="close" />
                        </StyledButton>
                    </Toolbar>
                </AppBar>
            </Dialog >
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
            >
                <Container maxWidth="lg" sx={{
                    pt: 3
                }}>
                    <Grid container spacing={2}>
                        <Grid item xs={30} sm={25} md={15}>
                            <Typography variant='h2'><FormattedMessage id="payment-details" /></Typography>
                            <StyledAccount>
                                <Avatar src='' alt="photoURL" />
                                <Box sx={{ ml: 2 }}>
                                    <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                                        {userDetails && userDetails.userName}
                                    </Typography>
                                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                        {userDetails && (userDetails.class + ' ' + userDetails.section)}
                                    </Typography>
                                </Box>
                            </StyledAccount>
                        </Grid>
                        <Grid item xs={30}>
                            <Stack direction='row'><LockRoundedIcon sx={{ marginRight: '2%' }} />
                                <Typography variant='body1'><FormattedMessage id="paymentalertmessage" /></Typography></Stack>
                        </Grid>
                        <Grid item xs={30}>
                            <Card align='center'>
                                <Stack direction='row' alignItems='center'>
                                    <Grid item xs={15}>
                                        <Typography variant='h8'><FormattedMessage id="Amount" /></Typography>
                                    </Grid>
                                    <Grid item xs={15}>
                                        <Typography variant='h4'>{amount}</Typography>
                                    </Grid>
                                </Stack>
                                <StyledDivider variant='middle' />
                                <Stack direction='row' alignItems='center'>
                                    <Grid item xs={15}>
                                        <Typography variant='h8'><FormattedMessage id="Term" /></Typography>
                                    </Grid>
                                    <Grid item xs={15}>
                                        <Typography variant='h4'>{term}</Typography>
                                    </Grid>
                                </Stack>
                            </Card>
                        </Grid>
                    </Grid>

                </Container >
                <DialogActions>
                    <StyledButton startIcon={(
                        <SvgIcon fontSize="small">
                            {color === 'error' && <CreditCardRoundedIcon />}
                            {color === 'success' && <GetAppRoundedIcon />}
                            {color === 'info' && <BlockRoundedIcon />}
                        </SvgIcon>
                    )} color={'success'} disabled={color === 'info'} onClick={
                        () => {
                            triggerPayment();
                            handlePrint();
                        }} >
                        {color === 'error' && <FormattedMessage id="pay-now" />}
                        {color === 'success' && <FormattedMessage id="download-receipt" />}
                        {color === 'info' && <FormattedMessage id="disabled" />}
                    </StyledButton>
                    <StyledButton startIcon={(
                        <SvgIcon fontSize="small">
                            <ClearRoundedIcon />
                        </SvgIcon>
                    )} color={'error'} onClick={handleClose}><FormattedMessage id="close" /></StyledButton>
                </DialogActions>
            </Dialog >
        </>
    );
}