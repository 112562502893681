import axios from "../axios";


export function fetchStaff(payload) {
    return axios.get('/staffDetails/fetchStaffs', payload);
}

export function fetchStudent(payload) {
    return axios.get('/studentDetails/fetchStudents', payload);
}

export function createStaffStudents(url, payload) {
    return axios.post(url, payload);
}

export function updateStudent(url, payload) {
    return axios.put(url, payload);
}

export function deleteStudent(payload) {
    return axios.delete('/studentDetails/deleteStudent', payload);
}