import React from 'react';
import { Field } from 'formik';
import { TextField, MenuItem } from '@mui/material';

export default function DropDownField ({ name, label, options, fullWidth, margin }) {
    return (
        <Field name={name}>
            {({ field }) => (
                <TextField
                    {...field}
                    select
                    label={label}
                    fullWidth={fullWidth}
                    margin={margin}
                    variant="outlined"
                >
                    {options.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
            )}
        </Field>
    );
};

