import axios from '../axios';

export function getFeeDetails(standard, admissionNumber) {
    return axios.get('/paidFeeDetails/fetchPaidFeeDetails', { params: { standard, admissionNumber, academicYear: '2023-2024' } });
}

export function intiatePayment(standard, admissionNumber, feeId) {
    return axios.post('/paymentGateway/initiatePayment', { standard, admissionNumber, feeId, academicYear: '2023-2024', language: 'EN' });
}

export function getPaymentDetails(standard, academicYear) {
    return axios.get('/paidFeeDetails/getPaymentInfo', { params: { merchant_param4: standard, merchant_param3: academicYear } });
}

export function getOverviewPayment(payload) {
    return axios.get('/paidFeeDetails/getOverViewPayment', payload);
}