import { Alert, Button } from "@mui/material";
import '../styles.css'
import { allFileTypes } from "../../../utils/constants";
import PropTypes from 'prop-types';
import { useState } from "react";


export const FileUploadStudentStaff = (props) => {

    FileUploadStudentStaff.propTypes = {
        fileData: PropTypes.func
    }

    const {
        fileData
    } = props;
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const uploadExcel = (event) => {
        if (event.target.files && event.target.files.length) {
            setErrorMessage('success');
            if (allFileTypes.spreadsheet.some(v => event.target.files[0].name.includes(v))) {
                fileData?.(event.target.files[0]);
                setSuccessMessage(`The file with name ${event.target.files[0].name} is uploaded successfully`)
            } else {
                setErrorMessage('Invalid file format. Please upload csv/excel file');
            }
        } else {
            setErrorMessage('Something went wrong. Please upload the file again.')
        }
    }

    return (
        <>
            {(errorMessage === '' ?
                <Alert severity="info">Upload the valid excel file below.</Alert>
                : errorMessage === 'success' ? <Alert severity="success">{successMessage}</Alert> : <Alert severity="error">{errorMessage}</Alert>)}
            <div className="displayFlex" style={{ marginTop: '20px' }}>
                <Button
                    variant="contained"
                    component="label"
                >
                    Upload File
                    <input
                        type="file"
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .xlsx"
                        hidden
                        onChange={uploadExcel}
                    />
                </Button>
            </div>
        </>
    );
};
