import { Navigate, useRoutes } from 'react-router-dom';
import DashboardLayout from '../layouts/dashboard';
import SimpleLayout from '../layouts/simple';
import Guard from '../guard/Guard';

//pages
import HomePage from '../pages/HomePage';
import DashBoardPage from '../pages/DashBoardPage';
import LoginPage from '../pages/LoginPage';
import Page404 from '../pages/ErrorPage/Page404';
import PaymentPage from '../pages/PaymentPage'
import StaffsPage from '../pages/StaffPage/StaffPage';
import StudentsPage from '../pages/StudentPage/StudentPage';
import UserDetails from '../pages/UserDetails';
import PaymentDetails from '../pages/PaymentDetailsPage/PaymentDetailsPage';
import AuditPage from '../pages/AuditPage';


export default function Router() {
  const routes = useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: <Guard><HomePage /></Guard> },
        { path: 'user', element: <Guard><UserDetails /></Guard> },
        { path: 'payment', element: <Guard><PaymentPage /></Guard> },
        { path: 'students', element: <Guard><StudentsPage /></Guard> },
        { path: 'staffs', element: <Guard><StaffsPage /></Guard> },
        { path: 'dashboard', element: <Guard><DashBoardPage /></Guard> },
        { path: 'paymentDetails', element: <Guard><PaymentDetails /></Guard> },
        { path: 'audit', element: <Guard><AuditPage /></Guard> }
      ],
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
