import axios from '../axios';

export function getClassDetails() {
    return axios.get('/dashboard/getClassDetails');
}

export function getTimeIntervalPayments(query) {
    return axios.get('/dashboard/timeInterval', { params: { ...query } })
}

export function getOverviewDetails(query) {
    return axios.get('/dashboard/overview', { params: { ...query } })
}