import PropTypes from 'prop-types';
import ReactApexChart from 'react-apexcharts';
import { useTheme, styled } from '@mui/material/styles';
import { Card, CardHeader, Divider, Typography } from '@mui/material';
import { fNumber } from '../../../../utils/formatNumber';
import { useChart } from '../../../../components/chart';

const CHART_HEIGHT = 372;
const LEGEND_HEIGHT = 72;

const StyledChartWrapper = styled('div')(({ theme }) => ({
    height: CHART_HEIGHT,
    marginTop: theme.spacing(5),
    '& .apexcharts-canvas svg': { height: CHART_HEIGHT },
    '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
        overflow: 'visible',
    },
    '& .apexcharts-legend': {
        height: LEGEND_HEIGHT,
        alignContent: 'center',
        position: 'relative !important',
        borderTop: `solid 1px ${theme.palette.divider}`,
        top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`,
    },
}));


Pie.propTypes = {
    title: PropTypes.string,
    subheader: PropTypes.string,
    chartColors: PropTypes.arrayOf(PropTypes.string),
    chartData: PropTypes.array,
};

export default function Pie({ title, subheader, chartColors, chartData, ...other }) {
    const theme = useTheme();

    const chartLabels = chartData.map((i) => i.label);

    const chartSeries = chartData.map((i) => i.value);

    const chartOptions = useChart({
        colors: chartColors,
        labels: chartLabels,
        stroke: { colors: [theme.palette.background.paper] },
        legend: { floating: true, horizontalAlign: 'center' },
        dataLabels: { enabled: true, dropShadow: { enabled: false } },
        tooltip: {
            fillSeriesColor: false,
            y: {
                formatter: (seriesName) => fNumber(seriesName),
                title: {
                    formatter: (seriesName) => `${seriesName}`,
                },
            },
        },
        plotOptions: {
            pie: {
                donut: {
                    size: '25%',
                    background: 'transparent',
                    startAngle: -90,
                    endAngle: 270,
                    labels: {
                        show: false,
                    }
                }
            },
        },
    });

    return (
        <Card {...other}>
            <CardHeader title={title} subheader={subheader} />
            <StyledChartWrapper dir="ltr">
                <ReactApexChart type="donut" series={chartSeries} options={chartOptions} height={280} />
            </StyledChartWrapper>
            <Divider />
            <Typography variant='caption'>**Note: Default conditions are students who paid fee directly</Typography>
        </Card>
    );
}
