const config = {
    production: {
        baseURL: 'https://api.clanofcoderz.com'
    },
    development: {
        baseURL: 'http://localhost:5000'
    }
};

export default config;
