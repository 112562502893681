import React, { useEffect } from "react";

const CrispChat = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://client.crisp.chat/l.js";
    script.async = true;
    document.getElementsByTagName("head")[0].appendChild(script);
    window.$crisp = [];
    window.CRISP_WEBSITE_ID = "260dfbe6-2672-4027-a4d5-fb3c3f2e2bdd";
  }, []);

  return <></>;
};

export default CrispChat;