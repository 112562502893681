export default function ToggleButton(theme) {
    return {
        MuiToggleButton: {
            styleOverrides: {
                root: {
                    "&.Mui-selected": {
                        color: "white",
                        backgroundColor: "#083f88"
                    },
                    "&:hover": {
                        color: "#083f88",
                        backgroundColor: "white"
                    }
                },
            }
        }
    };
}
